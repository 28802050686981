import React, { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet';

// Components
import ErrorBoundary from './ErrorBoundary';
import GlobalContext from "contexts/Global.context";

// Routes
import AppRoutes from './AppRoutes';

// Styles
import './styles/mia-base.scss';

export const App = () => {
  const { variantDetails } = useContext(GlobalContext);

  React.useEffect(() => {
    if (variantDetails?.site_short_title === "CCE") {
      const script = document.createElement("script");
      script.src = 'cce-tracking.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, [variantDetails?.site_short_title]);

  const faviconElement = document.getElementById("favicon") as HTMLLinkElement;
  faviconElement.href = `${variantDetails?.assets_url}/favicon.ico`;
  return (
    <ErrorBoundary>
      <Fragment>
        <Helmet>
          <title>{variantDetails?.site_short_title}</title>
        </Helmet>
        <AppRoutes />
      </Fragment>
    </ErrorBoundary>
  );
};