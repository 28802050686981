import React from "react";
import { Form } from "react-bootstrap";

// SCSS
import "./Radio.scss";

interface IRadio {
  id?: string;
  name?: string;
  label?: any;
  className?: string;
  isSelected?: boolean;
  isCompleted?: boolean;
  disabled?: boolean;
  onChange?: (event?: any) => void;
}

const Radio = (props: IRadio) => {
  return (
    <div className="customRadio">
      <Form.Check className={props.className}>
        <Form.Check.Input
          type="radio"
          name={props.name}
          id={props.id}
          checked={props.isSelected}
          disabled={props.disabled}
          onChange={props.onChange}
        />
        <Form.Check.Label className={props.isCompleted && !props.isSelected ? 'completed' : ''}>
          {props.label}
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
};

export default React.memo(Radio);
