import React, { Fragment, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Link, useLocation } from "react-router-dom";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagram,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

// State
import GlobalContext from "contexts/Global.context";

// Constants
import { ROUTES } from "constants/Constants";

// Components
import Button from "components/Form/Button/Button";
import Image from "components/Image/Image";

// Text
import COPY_OVERRIDES from "config/CopyOverrides";

// SCSS
import "./Footer.scss";

const Footer = () => {
  const { isLoggedIn, variantDetails } = useContext(GlobalContext);
  const location = useLocation();

  const LOGO = variantDetails?.footer_logo_url || `${variantDetails?.assets_url}/"footer_logo.svg"`;

  const backgroundImage =
    location.pathname === "/"
      ? `url(${variantDetails?.assets_url}/footer_shape.png)`
      : "";

  React.useEffect(() => {
    const script = document.createElement("script");
    if (variantDetails?.site_short_title?.includes("BeyondPhysician")) {
      script.src = "/bp-active-campaign.js";
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, [variantDetails?.site_short_title]);

  return (
    <Fragment>
      <div
        className={`footerWrapper ${location.pathname === "/" ? "homeFooter" : ""
          }`}
        style={{ backgroundImage: backgroundImage }}
      >
        <Container>
          <Row className="footerTop">
            <Col xl={8} lg={8} md={8} sm={12}>
              <div className="footerHeading">
                {COPY_OVERRIDES?.footer?.head || "Join our community."}
              </div>
              <div className="subHeading">
                {COPY_OVERRIDES?.homepage?.boost ||
                  "Boost your career or launch a new one today."}
              </div>
            </Col>
            {!isLoggedIn && (
              <Col xl={4} lg={4} md={4} sm={12}>
                <Row className="justify-content-lg-end">
                  <Col sm="auto">
                    <Button
                      link={ROUTES.REGISTER}
                      label="Sign Up"
                      type="large branding"
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Row className="footerBottom align-items-md-start">
            <Col md sm={12}>
              <Image
                className="footerLogo"
                src={LOGO}
                title={variantDetails?.site_short_title}
              />
              <div style={{ fontSize: "14px" }}>
                &#169;{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: variantDetails?.copyright || "<a href='http://beyondphysician.org' target='_blank' rel='noopener noreferrer'>Beyond Physician</a>, Inc. 2022",
                  }}
                />
              </div>
              <p className="disclaimer">
                All credentials offered within {variantDetails?.site_title}{" "}
                are non-accredited, both on a regional and national level.
              </p>
            </Col>
            <Col md sm={12}>
              <ul>
                <li>
                  <Link to={ROUTES.COURSES}>Courses</Link>
                </li>
                <li>
                  <Link to={ROUTES.CERTIFICATIONS}>Credentials</Link>
                </li>
                <li>
                  <Link to={ROUTES.ABOUT}>About</Link>
                </li>
                <li>
                  <Link to={ROUTES.REGISTER}>Register</Link>
                </li>
              </ul>
            </Col>
            <Col md sm={12}>
              <ul>
                <li>
                  <a
                    href="https://beyondphysician.org/faq/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    href="https://beyondphysician.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Marketplace
                  </a>
                </li>
              </ul>
            </Col>
            <Col md sm={12}>
              <ul>
                <li>
                  <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={ROUTES.TERMS}>Terms Of Services</Link>
                </li>
                <li>
                  <Link to={ROUTES.SUPPORT}>Contact</Link>
                </li>
                <li>
                  <a
                    href="https://beyondphysician.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Company
                  </a>
                </li>
              </ul>
            </Col>
            <Col md sm={12}>
              <div className="socialIcons">
                {variantDetails?.linkedin_link && (
                  <div>
                    <a
                      href={variantDetails?.linkedin_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                )}
                {variantDetails?.instagram_link && (
                  <div>
                    <a
                      href={variantDetails?.instagram_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </div>
                )}
                {variantDetails?.twitter_link && (
                  <div>
                    <a
                      href={variantDetails?.twitter_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                )}
                {variantDetails?.facebook_link && (
                  <div>
                    <a
                      href={variantDetails?.facebook_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default React.memo(Footer);
