import React, { useState, useContext } from 'react';
import { Row, Col } from "react-bootstrap";

import {
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';

import { Token, StripeError } from '@stripe/stripe-js';

import Button from 'components/Form/Button/Button';

import { addNewCard } from 'services/PaymentService';

import GlobalContext from 'contexts/Global.context';

import styles from './AddCard.module.scss';

const STRIPE_CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#111b47',
      minHeight: '55px',
      border: '1px solid #000000',
      '::placeholder': {
        color: '#979797',
      },
    }
  },
  hidePostalCode: true,
};

interface Props {
  onSuccess: () => void;
}

const AddCard: React.FC<Props> = ({ onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { setAuth } = useContext(GlobalContext);

  const [newCardLoader, setNewCardLoader] = useState<boolean>(false);
  const [newCardError, setNewCardError] = useState<string>('');

  const addCard = async () => {
    setNewCardError('');
    if (!stripe && !elements) {
      return;
    }

    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        setNewCardLoader(true);
        const { token, error }: { token?: Token | undefined; error?: StripeError | undefined; } = await stripe.createToken(cardElement);
        if (token) {
          const { token: userToken, error: cardError } = await addNewCard(token?.id);
          if (cardError) {
            setNewCardError(cardError);
          }
          else {
            cardElement.clear();
            if (userToken) {
              setAuth(userToken);
            }
            onSuccess();
          }
        } else if (error) {
          setNewCardError(error?.message);
        }
        setNewCardLoader(false);
      }
    }
  }

  return (
    <form className={styles.newCardForm}>
      <Row>
        <Col xl={8} lg={8} md={12} sm={12}>
          <CardElement
            options={STRIPE_CARD_ELEMENT_OPTIONS}
            onChange={() => setNewCardError('')}
          />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} className={styles.addCardBtn}>
          <Button
            type="primary blank"
            onClick={addCard}
            disabled={newCardLoader}
            label="Add Card"
          />
        </Col>
        {newCardError && <div className={styles.error}>
          {newCardError}
        </div>}
      </Row>
    </form>
  );
};

export default React.memo(AddCard);
