import React, { useState, useEffect, useCallback } from 'react';
import GlobalContext from '../contexts/Global.context';
import { Category } from 'interfaces/Category.types';
import { jwtDecode } from 'jwt-decode';
import History from '../utils/History';
import { fetchCategoriesService } from 'services/CommonService';

const GlobalState: React.FC<{children: React.ReactNode, variantDetails: any}> = (props: {children: React.ReactNode, variantDetails: any}) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token')
  );
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [quizDetails, setQuizDetails] = useState<any>(null);
  const [categories, setCategories] = useState<any[]>([]);

  const logout = useCallback(() => {
    setLoggedIn(false);
    localStorage.removeItem('token');
    History.push('/');
    setUserDetails({});
  }, []);

  const setAuth = (newToken: string | null) => {
    if (newToken) {
      localStorage.setItem('token', newToken);
      setLoggedIn(true);
      setToken(newToken);
      const userDetails = jwtDecode(newToken);
      setUserDetails(userDetails);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
        const categories: Array<Category> = await fetchCategoriesService();
        if (categories && categories.length) {
          const categoryList = categories.map(category => ({ label: category.name.trim() }));
          setCategories(categoryList);
        }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setAuth(localStorage.getItem('token'));
    }
  }, [token]);

  const wrap = {
    isLoggedIn: isLoggedIn,
    isLoading: isLoading,
    logout: logout,
    userDetails,
    setAuth: setAuth,
    setLoader: setIsLoading,
    quizDetails: quizDetails,
    setQuizDetails: setQuizDetails,
    categories: categories,
    variantDetails: props.variantDetails
  };

  return (
    <GlobalContext.Provider value={wrap}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
