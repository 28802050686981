import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from "contexts/Global.context";

//Components
import Welcome from './components/Welcome/Welcome';
import CareerMap from './components/CareerMap/CareerMap';

// SCSS
import styles from './About.module.scss';

const About = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <div className={styles.aboutPage}>
      <Helmet>
        <title>{variantDetails?.site_title} | About</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12}>
            <Welcome />
          </Col>
        </Row>
        {variantDetails?.site_short_title === 'MIA' &&
          <Row>
            <Col md={12}>
              <CareerMap />
            </Col>
          </Row>
        }
      </Container>
    </div>
  );
};

export default About;
