import React, { Fragment } from "react";
import History from 'utils/History';

import { ROUTES, CLOUDINARY_BANNER_URL } from 'constants/Constants';
import { getLimited } from 'utils/UtilityFunctions';
import OfficialPartner from "assets/svgs/officialicon.svg";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// SCSS
import styles from "./Course.module.scss";

// Props
interface ICourses {
  id?: string;
  image: string;
  category: string;
  title: string;
  description: string;
  count?: string | number;
  courseStatus?: string;
  isCompleted?: boolean;
  isOffsite?: boolean;
  externalLink?: string;
}

const Courses = (props: ICourses) => {
  
  const getCategoryColor = () => {
    if (props.category === 'Production') {
      return styles.green;
    }
    if (props.category === 'Guitar') {
      return styles.purple;
    }
    if (props.category === 'Electronic') {
      return styles.blue;
    }
    return styles.normal;
  }

  const gotoDetails = () => {
    History.push(`${ROUTES.COURSES}/${props.id}`);
  }

  return (
    <Fragment>
      <div className={`${styles.listWrapper} ${props.courseStatus}`}>
        <div
          className={`${props.isCompleted ? styles.disabled : ""}`}
          onClick={gotoDetails}
        >
          <div className={styles.imgWrapper}>
            <img
              alt={props.title}
              src={
                props.image
                  ? `${CLOUDINARY_BANNER_URL}${props.image}`
                  : "https://placehold.co/600x400"
              }
            />
          </div>
          <div className={styles.courseDetails}>
            <div className={styles.courseCat}>
              <span className={getCategoryColor()}>{props.category}</span>
            </div>
            <div className={styles.courseTitle}>{props.title}</div>
            <div className={styles.courseDescription}>
              {getLimited(props.description, 150)}
            </div>
            {/* {props.count >= 0 && <div className={styles.memberCount}>
              <FontAwesomeIcon className={styles.users} icon={faUsers} />
              <div className={styles.text}>
                <span> {props.count}</span> registered
              </div>
            </div>} */}
            {props.isOffsite && props.externalLink && (
              <div className={styles.partnerContent}>
                <img src={OfficialPartner} alt="Partner" />
                Partner
              </div>
            )}
          </div>
        </div>
        {props.isCompleted && (
          <FontAwesomeIcon className={styles.check} icon={faCheckCircle} />
        )}
      </div>
    </Fragment>
  );
};

export default Courses;
