import React, { Fragment, useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet';

import { Route, useLocation } from "react-router-dom";

// Page Components
import DashboardMenu from "./Components/DashboardMenu/DashboardMenu";
import QuizNode from "./Components/QuizNode/QuizNode";
import MyCertifications from "./Components/MyCertifications/MyCertifications";
import DashboardContent from "./Components/DashboardContent/DashboardContent";
import DashboardTopCourses from "./Components/DashboardTopCourses/DashboardTopCourses";
import MyStats from "./Components/MyStats/MyStats";
import BillingProfile from "./Components/BillingProfile/BillingProfile";

// State
import GlobalContext from "contexts/Global.context";
import DashboardState from "./contexts/Dashboard.state";

// Constants
import { ROUTES } from "constants/Constants";

// SCSS
import styles from "./Dashboard.module.scss";

const Dashboard = () => {
  const { userDetails: { first_name, last_name, email }, quizDetails, variantDetails } = useContext(GlobalContext);
  const [ activeStatsTab, setActiveStatsTab ] = useState<number>(0);
  const { pathname } = useLocation();
  const { QUIZ_NODE, DASHBOARD, MY_CERTIFICATIONS, PROFILE, QUIZ_DETAIL } = ROUTES;

  const handleTabChange = (tab) => {
    setActiveStatsTab(tab);
  };

  return (
    <DashboardState>
      <Fragment>
        <Helmet>
          <title>{variantDetails?.site_title} | Dashboard</title>
        </Helmet>
        <Container className={styles.dashboardWrapper}>
          <Row>
            <Col>
              {!pathname.includes(QUIZ_DETAIL) ? (
                <div className={styles.dashboardTop}>
                  <div className={styles.pageHeading}>Dashboard</div>
                  <div className={styles.pageUser}>
                    Welcome, {first_name} {last_name}
                  </div>
                  <div className={styles.userEmail}>{email}</div>
                  <div
                    className={styles.dashboardImage}
                    style={{
                      backgroundImage: `url(${variantDetails?.assets_url}/dashboard_heading.png)`,
                    }}
                  ></div>
                </div>
              ) : (
                <div className={styles.dashboardTop}>
                  <div className={styles.pageHeading}>Quiz</div>
                  <div className={styles.pageUser}>{quizDetails?.title}</div>
                  <div className={styles.userEmail}>
                    {quizDetails?.category}
                  </div>
                  <div
                    className={styles.dashboardImage}
                    style={{
                      backgroundImage: `url(${variantDetails?.assets_url}/dashboard_heading.png)`,
                    }}
                  ></div>
                </div>
              )}
            </Col>
          </Row>
          <Row className={pathname.includes(QUIZ_DETAIL) ? styles.quizPageWrapper : ''}>
            <Col lg={3} md={4}>
              <DashboardMenu />
            </Col>
            <Col lg={9} md={8}>
              <Container className={styles.dashboardContent}>
                {!pathname.includes(QUIZ_DETAIL) &&
                pathname !== PROFILE &&
                pathname !== MY_CERTIFICATIONS ? (
                  <MyStats
                    handleTabChange={handleTabChange}
                    activeTab={activeStatsTab}
                  />
                ) : null}
                <Route
                  exact
                  path={DASHBOARD}
                  render={() => <DashboardContent activeTab={activeStatsTab} />}
                />
                <Route path={QUIZ_NODE} component={QuizNode} />
                <Route
                  exact
                  path={MY_CERTIFICATIONS}
                  component={MyCertifications}
                />
                <Route exact path={PROFILE} component={BillingProfile} />
              </Container>
            </Col>
          </Row>
          {pathname === "/dashboard" && (
            <>
              <Row>
                <Col>
                  <div className={styles.sectionHeading}>Top Courses</div>
                </Col>
              </Row>
              <DashboardTopCourses />
            </>
          )}
        </Container>
      </Fragment>
    </DashboardState>
  );
};

export default React.memo(Dashboard);
