import React, { Fragment, useContext } from "react";

import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

import CONFIGURATION from 'config/Configuration';

import GlobalContext from "contexts/Global.context";

//SCSS;
import styles from "./Slider.module.scss";
import "./Slider.scss";

const Slider = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <Fragment>
      <Container className={styles.carouselWrapper}>
        <Row>
          <Col>
            {CONFIGURATION?.home_slider ? 
              <Carousel className={styles.carouselOuter}>
                {CONFIGURATION?.home_slider.map((item, index) => <Carousel.Item key={item.author + index}>
                  <Row className={styles.sliderPosition}>
                    <Col xl="6" lg="12">
                      <Carousel.Caption className={styles.captionSection}>
                        <div className={styles.leftSection}>
                          <div className={styles.subheading}>
                            {item.title}
                          </div>
                          <div className={styles.heading}>
                            {item.body}
                          </div>
                          <div className={styles.bottomSection}>
                            <div className={styles.author}>{item.author}</div>
                            <div className={styles.location}>
                              {item.location}
                            </div>
                          </div>
                        </div>
                      </Carousel.Caption>
                    </Col>
                    <Col xl="6" lg="12">
                    <div className={styles.imgWrapper} >
                      <img src={`${variantDetails?.assets_url}/${item.image}`} alt="slide" />
                    </div>
                    </Col>
                  </Row>
                </Carousel.Item>)}
              </Carousel>
              :
              <Carousel className={styles.carouselOuter}>
                <Carousel.Item>
                  <Row className={styles.sliderPosition}>
                    <Col xl="6" lg="12">
                      <Carousel.Caption className={styles.captionSection}>
                        <div className={styles.leftSection}>
                          <div className={styles.subheading}>
                            Don’t take our word for it
                          </div>
                          <div className={styles.heading}>
                            I would not be where I am in my career without the Music Industry Academy
                          </div>
                          <div className={styles.bottomSection}>
                            <div className={styles.author}>Jessica S.</div>
                            <div className={styles.location}>
                              Los Angeles, California
                            </div>
                          </div>
                        </div>
                      </Carousel.Caption>
                    </Col>
                    <div className={styles.imgWrapper} >
                      <img src={`${variantDetails?.assets_url}/home_slide_1.jpg`} alt="First slide" />
                    </div>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row className={styles.sliderPosition}>
                    <Col xl="6" lg="12">
                      <Carousel.Caption className={styles.captionSection}>
                        <div className={styles.leftSection}>
                          <div className={styles.subheading}>
                            Don’t take our word for it
                          </div>
                          <div className={styles.heading}>
                            This is everything you need, right here...everything you need to get your career going.
                          </div>
                          <div className={styles.bottomSection}>
                            <div className={styles.author}>Erik J.</div>
                            <div className={styles.location}>
                              Los Angeles, California
                            </div>
                          </div>
                        </div>
                      </Carousel.Caption>
                    </Col>
                    <div className={styles.imgWrapper}>
                      <img src={`${variantDetails?.assets_url}/home_slide_2.jpg`} alt="second slide" />
                    </div>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row className={styles.sliderPosition}>
                    <Col xl="6" lg="12">
                      <Carousel.Caption className={styles.captionSection}>
                        <div className={styles.leftSection}>
                          <div className={styles.subheading}>
                            Don’t take our word for it
                          </div>
                          <div className={styles.heading}>
                            It's like they created a road map for the entire music industry!
                          </div>
                          <div className={styles.bottomSection}>
                            <div className={styles.author}>Ryan G.</div>
                            <div className={styles.location}>
                              Los Angeles, California
                            </div>
                          </div>
                        </div>
                      </Carousel.Caption>
                    </Col>
                    <div className={styles.imgWrapper}>
                      <img src={`${variantDetails?.assets_url}/home_slide_3.jpg`} alt="third slide" />
                    </div>
                  </Row>
                </Carousel.Item>
              </Carousel>
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default React.memo(Slider);
