import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "contexts/Global.context";

//SCSS;
import styles from "./OurPartner.module.scss";

const OurPartner = () => {
  const { variantDetails } = useContext(GlobalContext);
  const partners = variantDetails.partners || [{
    "name": "Loud Capital",
    "link": "https://loud.vc/",
    "logo": `${variantDetails?.assets_url}/partner_${1}.png`,
  },
  {
    "name": "Career Map",
    "link": "https://mycareermaps.com/",
    "logo": `${variantDetails?.assets_url}/partner_${2}.png`,
  }];

  return (
    !!partners.length ? <Container className={`${styles.partnerWapper}`}>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.sectionHeading}>Proudly Partnered With</div>
        </Col>

        <Col xl={12} lg={12} md={12} sm={12}>
          <Row className="justify-content-md-center align-items-center">
            {partners.map(partner => {
              return (
                <Col key={partner.name} md="auto" className={styles.partnerItem}>
                  <div className={styles.partnerImage}>
                    <a
                      href={partner.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={partner.logo} alt={partner.name} />
                    </a>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container> : null
  );
};

export default React.memo(OurPartner);
