import React, { Fragment, useState } from "react";
// import downloadjs from 'downloadjs';

// SVGs
import Success from "assets/svgs/success.svg";
import Faliure from "assets/svgs/failure.svg";

// import QuestionAndOptions from "components/QuestionAndOptions/QuestionAndOptions";
import Button from "components/Form/Button/Button";

// Types
import { QuizRegistration } from "interfaces/Quiz.types";

import { downloadCert } from "services/QuizService";

// import GlobalContext from 'contexts/Global.context';

import { ROUTES } from "constants/Constants";

// Styles
import styles from "./Result.module.scss";

interface ScoreProps {
  result: string;
  answers: any;
  registration: QuizRegistration;
}

const Result: React.FC<ScoreProps> = ({ result, answers, registration }) => {
  // const { userDetails } = useContext(GlobalContext);
  const { CERTIFICATIONS } = ROUTES;
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  
  const downloadCertificate = async () => {
    setIsDownloading(true);
    const { error, data } = await downloadCert(registration.quiz);
    if (!error) {
      window.open(data.url, "__blank");
      // downloadjs(data, `certificate_${userDetails.first_name}_${userDetails.last_name}.pdf`, 'application/pdf');
    }
    setIsDownloading(false);
  };

  return (
    <Fragment>
      <div className={styles.resultSection}>
        <div className={styles.sectionHeading}>Final Results</div>
        {result === 'passed' ? <div className={styles.resultWrapper}>
          <div className={styles.imgWrap}>
            <img src={Success} alt="Success" />
          </div>
          <div className={styles.resultDes}>
            <div className={styles.resultDesHead}>
              You answered {registration.correct_answers} out of {registration.total_questions} correct.
            </div>
            <div className={styles.resultDesSubHead}>You passed!</div>
          </div>
        </div> :
        <div className={styles.resultWrapper}>
          <div className={styles.imgWrap}>
            <img src={Faliure} alt="Faliure" />
          </div>
          <div className={styles.resultDes}>
            <div className={styles.resultDesHead}>
              You answered {registration.correct_answers} out of {registration.total_questions} correct.
            </div>
            <div
              className={`${styles.resultDesSubHead} ${styles.errorSubHead}`}
            >
              You didn’t pass this time.
            </div>
          </div>
        </div>}
        {/* {answers.map((question, index) => {
          return (
            <Fragment key={index}>
              <div className={styles.questionCount}>Question {index + 1}</div>
              <div className={styles.quizContent}>
                <QuestionAndOptions
                  question={question.question}
                  answer={question.answer}
                  options={question.options}
                  isResult={true}
                />
              </div>
            </Fragment>
          )
        })} */}
        <div className={styles.resultBtnWrapper}>
          {result === 'passed' ?
            <Button onClick={downloadCertificate} disabled={isDownloading} label="Download Your Certificate" type="large branding" />
          :
            <Button link={CERTIFICATIONS} label="Return To Quiz Listing Page" type="large branding" />
          }  
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(Result);
