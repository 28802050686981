import React, { useState, useEffect } from "react";
import { Col, Row } from 'react-bootstrap';

// services
import { fetchUserQuizzes } from 'services/QuizService';

import { QuizList } from 'interfaces/QuizList.types';

import NoCourse from "assets/imgs/courses/no-course.png";

// Styles
import styles from "./MyCertifications.module.scss";

//components
import Certificate from "components/Certificate/Certificate"
import { Loader } from "components/Loader/Loader";

const RESULTS_TO_FETCH = 100;

const MyCertifications = () => {

  const [certifications, setCertifications] = useState<QuizList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getMyCertifications = async () => {
      setLoading(true);
      const { quizzes, error } = await fetchUserQuizzes(RESULTS_TO_FETCH, null, true);
      if (!error) {
        setCertifications(quizzes);
      }
      setLoading(false);
    };
    getMyCertifications();
  }, [setCertifications, setLoading]);

  return (
    <div className={styles.gridListing}>
      <div className={styles.heading}>All Your Credentials</div>
      <div className={styles.list}>
        <Row>
          {!loading && certifications.map(quiz => {
            return (
              <Col key={quiz.id} xl="4" lg="4" md="6" xs="12" className={styles.list}>
                <Certificate
                  id={quiz.id}
                  image={quiz.banner}
                  category={quiz.category}
                  title={quiz.title}
                  description={quiz.description}
                  downloadable={true}
                />
              </Col>
            );
          })}
          {!loading && !certifications.length &&
            <Col xl="4" lg="4" md="6" xs="12" className={styles.list}>
              <Certificate
                image={NoCourse}
                category=""
                title="No Certification Found."
                description="Each journey starts with a single step. Select a quiz and begin your journey."
              />
            </Col>
          }
          {loading && <Loader/>}
        </Row>
      </div>
    </div>
  );
};

export default React.memo(MyCertifications);
