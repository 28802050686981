import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';

import styles from './Select.module.scss';

export interface SelectItem {
  label: string;
  id?: string;
  value?: string;
}

interface Props {
  id: string;
  name?: string;
  value?: string | Array<string>;
  selectItems: Array<SelectItem>;
  onChange: (event: any) => void;
  multiple?: boolean;
  placeholder?: string; 
}

const Select: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.mdrSelect}>
      <Form.Control
        as="select"
        value={props.value}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        multiple={props.multiple || false}
      >
        <option key={'select'} value="">
          {props.placeholder || 'Select'}
        </option>
        {props.selectItems.map((item, index) => (
          <Fragment key={index}>
            <option key={`${index}${item.label}`} value={item.value || item.label}>{item.label}</option>
          </Fragment>
        ))}
      </Form.Control>
    </div>
  );
};

export default React.memo(Select);
