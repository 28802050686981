import React, { useState, useCallback } from 'react';

// context
import HomeContext from './Home.context';

// interface
import { CourseList } from 'interfaces/CourseList.types';
import { QuizList } from 'interfaces/QuizList.types';

// services
import { fetchTopCourses } from 'services/CourseService';
import { fetchTopQuizzes } from 'services/QuizService';

const HomeState: React.FC<{ children: React.ReactNode }> = (props: { children: React.ReactNode }) => {
  const [courses, setCourses] = useState<CourseList[]>([]);
  const [quizzes, setQuizzes] = useState<QuizList[]>([]);
  const [totalCourses, setTotalCourses] = useState<number>(0);
  const [totalQuizzes, setTotalQuizzes] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCourses = useCallback(async (filters?: any, limit?: number) => {
    setCourses([]);
    setTotalCourses(0);
    setIsLoading(true);
    const { category, industry, cost } = filters || {};
    const { courses, total, error } = await fetchTopCourses(category, limit, null, null, { industry, cost });
    if (!error) {
      setCourses(courses);
      setTotalCourses(total);
    }
    setIsLoading(false);
  }, []);

  const getQuizzes = useCallback(async (filters?: any, limit?: number, orderBy?: string) => {
    setQuizzes([]);
    setIsLoading(true);
    const { category, industry } = filters || {};
    const { quizzes, total, error } = await fetchTopQuizzes(limit, orderBy, category, { industry });
    if (!error) {
      setQuizzes(quizzes);
      setTotalQuizzes(total);
    }
    setIsLoading(false);
  }, []);

  const wrap = {
    isLoading,
    setCourses,
    setQuizzes,
    setIsLoading,
    courses,
    quizzes,
    totalCourses,
    totalQuizzes,
    getCourses,
    getQuizzes
  };

  return (
    <HomeContext.Provider value={wrap}>
      {props.children}
    </HomeContext.Provider>
  );
};

export default HomeState;
