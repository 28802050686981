import React from "react";
import { Button as Btn } from "react-bootstrap";

// SCSS
import "./Button.scss";

interface Props {
  label: string;
  link?: string;
  size?: any | "md"; // sm, md, lg
  type?: string | "branding"; // branding, *-outline, transparent
  buttonType?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  isLoading?: boolean; // loading handler
  onClick?: () => void; // optional onClick event
}

const Button: React.FC<Props> = ({
  label,
  link,
  size,
  type,
  buttonType,
  disabled,
  isLoading,
  onClick,
}: Props) => {
  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  };

  return (
    <Btn
      href={link}
      size={size}
      className={`miaButton miaBtn ${type}`}
      type={buttonType}
      disabled={disabled}
      onClick={!isLoading ? handleClick : () => {}}
    >
      {isLoading ? "Loading..." : label}
    </Btn>
  );
};

export default React.memo(Button);
