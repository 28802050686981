import { API_ENDPOINTS, API_METHODS, DEFAULT_API_HEADER } from '../constants/Constants';
import { camelToSnake } from 'utils/UtilityFunctions';
import { UserCredentials } from '../interfaces/Register.types';

const stringifyMe = (obj: any) => JSON.stringify(obj);

/**
 *
 * @param userDetails All the user details input by new user while registering
 * This service is used for registering the users
 */
export const registerUserService = (userDetails: any) => {
  return fetch(API_ENDPOINTS.USERS, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
    },
    body: stringifyMe({ ...userDetails }),
  });
};

/**
 *
 * @param userCredentails
 * Service for logging in the user
 */
export const loginUserService = (userCredentails: UserCredentials) => {
  return fetch(API_ENDPOINTS.LOGIN, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
    },
    body: stringifyMe({ ...userCredentails }),
  });
};

/**
 * @param token A valid token which was sent to the user over email for resetting the password
 * @param password New password
 * Service for resetting the user's password
 */
export const resetPasswordService = (token: string, password: string) => {
  return fetch(`${API_ENDPOINTS.RESET_PASSWORD}/${token}`, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
    },
    body: stringifyMe({ password, company: (window as any).SITE_SHORT_TITLE }),
  });
};

/**
 * @param email
 * Service used for sending an email to the user to let user change password
 */
export const forgotPasswordService = (email: string) => {
  return fetch(API_ENDPOINTS.FORGOT_PASSWORD, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
    },
    body: stringifyMe({ email, company: (window as any).SITE_SHORT_TITLE }),
  });
};

export const fetchUserDetailsService = async (userId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.USERS}/${userId}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const user = await response.json();
      return { user };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchMyStats = async () => {
  try {
    const response = await fetch(`${API_ENDPOINTS.USERS}/stats`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const stats = await response.json();
      return { stats };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const updateMyProfile = async (userId: string, body: any) => {
  try {
    const data = {};
    Object.keys(body).map(key => {
      data[camelToSnake(key)] = body[key];
      return key;
    });
    const response = await fetch(`${API_ENDPOINTS.USERS}/${userId}`, {
      method: API_METHODS.PUT,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const { token } = await response.json();
      return { token };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const subscribe = async (cardId: string, plan: string, couponId?: string) => {
  try {
    const response = await fetch(API_ENDPOINTS.SUBSCRIPTION, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ cardId, plan, coupon_id: couponId }),
    });

    if (response.ok) {
      const { token } = await response.json();
      return { token };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const unsubscribe = async () => {
  try {
    const response = await fetch(API_ENDPOINTS.SUBSCRIPTION, {
      method: API_METHODS.DELETE,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const { token } = await response.json();
      return { token };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchMyTransactions = async (limit?: number) => {
  try {
    let qs = `?orderBy=created_at`;
    if (limit) {
      qs += `&limit=${limit}`;
    }
    const response = await fetch(`${API_ENDPOINTS.USERS}/transactions${qs}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const { transactions } = await response.json();
      return { transactions };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};