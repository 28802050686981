import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player/youtube";

// SCSS
import styles from "./CourseContent.module.scss";

// Components
import Button from "components/Form/Button/Button";

// Components
import CourseComplete from "../../Components/CourseComplete/CourseComplete";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faDownload } from "@fortawesome/free-solid-svg-icons";

// Types
import { Modules } from "interfaces/Course.types";

// Props
interface Props {
  activeLesson: number;
  lesson: Modules;
  gotoPreviousModule: () => void;
  gotoNextModule: () => void;
  completeCourse: () => void;
  quiz?: string;
  completedLessons: number;
  isCompleted: boolean;
  totalLessons: number;
  variantDetails: any;
}

const CourseContent: React.FC<Props> = ({
  activeLesson,
  lesson,
  gotoPreviousModule,
  gotoNextModule,
  quiz,
  completedLessons,
  isCompleted,
  totalLessons,
  completeCourse,
  variantDetails,
}) => {
  const DEFAULT_PHOTO = `${variantDetails?.assets_url}/header_logo_thumb.svg`;
  let attachments: any = lesson?.attachments || [];
  try {
    if (typeof attachments === "string") {
      attachments = JSON.parse(attachments);
    }
  } catch (e) { }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://muse.ai/static/js/embed-player.min.js";
    script.async = true;
    document.head.appendChild(script);
  }, []);

  const getVideoCode = (url: string) => {
    if (!url) {
      return null;
    }
    if (url.includes("dacast")) {
      return (
        <iframe
          title="dacast"
          id={url.substring(lesson.media.lastIndexOf("/") + 1)}
          src={url}
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          allow="autoplay;encrypted-media"
          allowFullScreen
        ></iframe>
      );
    }
    if (url.includes("vimeo")) {
      return (
        <iframe
          title="vimeo"
          src={`https://player.vimeo.com/video/${url.substring(
            lesson.media.lastIndexOf("/") + 1
          )}`}
          width="100%"
          x-frame-ptions
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      );
    }
    if (url.includes("muse.")) {
      return (
        <iframe
          title="muse.ai"
          src={lesson.media}
          width="100%"
          x-frame-ptions
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      );
    }
    return (
      <ReactPlayer
        url={url}
        width={"100%"}
        height={"100%"}
        config={{
          playerVars: { showinfo: 1, controls: 1 }
        }}
      />
    );
  };

  return (
    <Fragment>
      <div className={styles.courseContentWrapper}>
        {activeLesson < totalLessons ? (
          <div className={styles.courseContent}>
            <div className={styles.courseVideo}>
              {getVideoCode(lesson?.media)}
            </div>
            <div className={styles.courseCategory}>{lesson?.category}</div>
            <Row>
              <Col md={5}>
                <div className={styles.courseHeading}>{lesson?.name}</div>
                <div className={styles.controls}>
                  <div>
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                  <div>Video Lesson</div>
                  <div>{lesson?.duration}</div>
                </div>
                {attachments && attachments.length ? (
                  <div className={styles.downloadCourse}>
                    <div className={styles.heading}>
                      Course Downloads &amp; Documents
                    </div>
                    <div className={styles.dowloadList}>
                      {attachments.map((item, index) => {
                        return (
                          <div key={index}>
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FontAwesomeIcon icon={faDownload} />
                              {item.name}
                            </a>
                            <div className={styles.size}>{item.size}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {activeLesson > 0 && (
                  <div>
                    <Button
                      onClick={() => gotoPreviousModule()}
                      label="Go Back"
                      type="large btnArrowLeft"
                    />
                  </div>
                )}
              </Col>
              <Col md={7}>
                <div className={styles.aboutCourse}>
                  {activeLesson < totalLessons - 1 && (
                    <div className={styles.btnWrapper}>
                      <Button
                        onClick={() => gotoNextModule()}
                        label="Next Lesson"
                        type="large branding"
                      />
                    </div>
                  )}
                  {activeLesson === totalLessons - 1 && (
                    <div className={styles.btnWrapper}>
                      <Button
                        onClick={() => completeCourse()}
                        label="Finish"
                        type="large branding"
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className={styles.courseInstructor}>
                  <div className={styles.instHeading}>Meet Your Instructor</div>
                  <div className={styles.intructorDetail}>
                    <div className={styles.img}>
                      <img
                        alt="Instructor"
                        src={
                          lesson?.instructor_photo ||
                          DEFAULT_PHOTO
                        }
                      />
                    </div>
                    <div className={styles.insDetailInner}>
                      <div
                        className={styles.instName}
                      >{`${lesson?.first_name} ${lesson?.last_name}`}</div>
                      <div className={styles.shortDes}>
                        {lesson?.instructor_bio}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.description}>{lesson?.description}</div>
              </Col>
            </Row>
          </div>
        ) : (
          <CourseComplete quiz={quiz} />
        )}
      </div>
    </Fragment>
  );
};

export default React.memo(CourseContent);
