import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import { Container, Row, Col, Alert } from 'react-bootstrap';

// Components
import Button from 'components/Form/Button/Button';
import Input from 'components/Form/Input/Input';

// Constants
import { APP_CONSTANTS as Constants, ROUTES } from 'constants/Constants';

// Context
import GlobalContext from 'contexts/Global.context';

// Services
import { loginUserService } from 'services/UserService';

// SCSS
import styles from './Login.module.scss';

interface LoginFormData {
  username: string;
  password: string;
}

const {
  errorConstants: { USER_NAME_NOT_ENTERED, PASSWORD_NOT_ENTERED_ERROR },
} = Constants;

const Login: React.FunctionComponent = () => {
  const { setAuth, isLoggedIn, userDetails, variantDetails } = useContext(GlobalContext);
  const LoginBG = `${variantDetails?.assets_url}/login.png`;

  const [formData, setFormData] = useState<LoginFormData>({
    username: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState<LoginFormData>({
    username: '',
    password: '',
  });

  const [loading, setLoading] = useState<boolean>(false);
  // const [response, setResponse] = useState<APIResponse | null>(null);
  const [apiError, setApiError] = useState<string>('');

  /**
   * Handler that gets called when text in input field changes
   * @param {Object} event The event object
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'username') {
      if (!value) {
        setFormErrors({
          ...formErrors,
          [name]: USER_NAME_NOT_ENTERED,
        });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: '',
        });
      }
    }

    if (name === 'password') {
      if (!value) {
        setFormErrors({
          ...formErrors,
          [name]: PASSWORD_NOT_ENTERED_ERROR,
        });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: '',
        });
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /**
   * Function to disable/enable the submit button
   */
  const isSubmitDisabled = (): boolean => {
    const { username, password } = formData;
    if (username && password) {
      return false;
    }

    return true;
  };

  /**
   *
   * @param {Object} errors The form errors
   */
  const validateForm = (errors: LoginFormData) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  /**
   * Login user once he submits or click on the login button
   */
  const loginUser = async () => {
    setApiError('');
    setLoading(true);
    const userCredentials = {
      user_name: formData.username,
      password: formData.password,
    };
    const response = await loginUserService(userCredentials);

    if (!response.ok) {
      // TODO: After discussing the error response
      const error = await response.clone().text();
      setApiError(error);
      setLoading(false);
    } else {
      const { token } = await response.json();
      setAuth(token);
    }
  };

  /**
   * Try logging in the user once the user submit's the form
   */
  const handleLoginSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm(formErrors)) {
      loginUser();
    }
  };

  if (isLoggedIn && userDetails && userDetails.id) {
    if (variantDetails?.site_short_title === "CCE") {
      if (userDetails && !userDetails.is_subscribed) {
        return <Redirect to={ROUTES.SUBSCRIPTION} />;
      }
    }
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('destination=') > -1) {
      const toUrl = currentUrl.substring(currentUrl.lastIndexOf('=') + 1);
      return <Redirect to={toUrl} />;
    }
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Helmet>
        <title>{variantDetails?.site_title} | Login</title>
      </Helmet>
      <Container className={`${styles.authPage}`} fluid>
        <Container className={styles.authPageWrap}>
          <Row className="align-content-center">
            <Col
              className={`d-xl-block d-lg-block d-md-none d-sm-none d-none ${styles.authIntro}`}
              xl={6}
              lg={6}
              md={6}
              sm={12}
            >
              <img
                src={LoginBG}
                className={`img-fluid ${styles.authBG}`}
                alt="Login"
              />
              <div className={styles.authTitleData}>
                <div className={styles.authTitle}>Login</div>
                <div className={styles.authSubTitle}>Welcome back.</div>
              </div>
            </Col>
            <Col className={`${styles.authForm}`} xl={6} lg={6} md={12} sm={12}>
              <form onSubmit={handleLoginSubmit}>
                <div className={styles.authFormWrap}>
                  <div className={styles.authFormTitle}>Login</div>

                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Username</div>
                    <Input
                      className={formErrors.username ? styles.errorInput : ""}
                      name="username"
                      type="text"
                      value={formData.username}
                      onChange={handleInputChange}
                    />
                    {formErrors.username && (
                      <div className={styles.error}>{formErrors.username}</div>
                    )}
                  </div>

                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Password</div>
                    <Input
                      className={formErrors.password ? styles.errorInput : ""}
                      name="password"
                      type="password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                    {formErrors.password && (
                      <div className={styles.error}>{formErrors.password}</div>
                    )}
                  </div>
                  <div className={styles.forgotPasswordLink}>
                    <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
                  </div>
                  <div className={styles.errorWrapper}>
                    {apiError && <Alert variant="danger">{apiError}</Alert>}
                  </div>

                  <div className={styles.authButton}>
                    <Button
                      type="large w-100"
                      buttonType="submit"
                      label={loading ? "loading..." : "Sign Into My Account"}
                      disabled={loading || isSubmitDisabled()}
                    />
                  </div>

                  <div className={styles.authSubText}>
                    Don&apos;t have an account?
                    <Link to={ROUTES.REGISTER}>Sign Up</Link>
                  </div>
                  <div className={styles.diffSite}>
                    <a href="/">Beyond Physician's Academy</a> is a different product from
                    our <a href="https://beyondphysician.com" target="_blank" rel="noopener noreferrer">Marketplace</a>, and you will need to create a separate
                    account for the Academy due to our work in institutional
                    education. You can use your same email and password when
                    setting up your Academy account.
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default React.memo(Login);
