import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

import GlobalContext from 'contexts/Global.context';

import styles from './Privacy.module.scss';

const Privacy = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <>
      <Helmet>
        <title>{variantDetails?.site_title} | Privacy</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12} className="justify-content-center">
            {!variantDetails?.site_short_title.includes("BeyondPhysician") ? (
              <div className={styles.privacyContainer}>
                <div className={styles.pageHeader}>Privacy Policy</div>

                <div className={styles.section}>
                  <p>
                    <i>Last revised October 1, 2020</i>
                  </p>
                  <p>
                    At Music Career Map, Inc. we respect our customers’ privacy.
                    This privacy policy (“Policy”) applies to all activities,
                    including, but not limited to our websites, mobile sites,
                    services, applications, platforms and tools where this
                    Policy appears or is linked (collectively, our “Website”).
                    This Policy is to inform you about how we collect, use,
                    share, and protect personal information. By providing
                    information to us (by any means in correspondence, via our
                    Website or otherwise) you consent to our use of the
                    information you provide in accordance with this Policy. Any
                    reference to our Website includes its e-mail, text, live
                    chat, and other electronic messages. If you do not agree
                    with this Policy or our practices, you may not use our
                    Website. This Policy may change from time to time. Your
                    continued use of our Website constitutes your acceptance of
                    those changes. We encourage you to review this Policy
                    periodically.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Information We Collect</p>
                  <p>
                    We collect personal information about you in various ways,
                    such as when you provide it on our Website, through our apps
                    and social media channels, at events, through your
                    participation in surveys, via text messages or on the
                    telephone. The types of personal information we may collect
                    include:
                  </p>
                  <ul>
                    <li>
                      contact information (such as name, postal address, email
                      address, and mobile or other telephone number);
                    </li>
                    <li>
                      username and password if you have registered an account
                      with us;
                    </li>
                    <li>
                      payment information (such as your payment card number,
                      expiration date, authorization number or security code,
                      delivery address, and billing address);
                    </li>
                    <li>purchase, refund, and transaction information;</li>
                    <li>
                      customer service information (such as customer service
                      inquiries, comments and history);
                    </li>
                    <li>device information;</li>
                    <li>
                      photographs, comments and other content you provide;
                    </li>
                    <li>
                      information regarding your personal or professional
                      interests, date of birth, anniversary date, demographics
                      (such as gender, occupation, income and marital status),
                      experiences with our products and services, and contact
                      preferences;
                    </li>
                    <li>social media information;</li>
                    <li>
                      contact information you provide about friends or other
                      people you would like us to contact; and
                    </li>
                    <li>
                      information we may obtain from our third-party service
                      providers.
                    </li>
                  </ul>
                  <p>
                    In addition, when you visit our Website, open our emails,
                    use our apps or interact with our related tools, widgets or
                    plug-ins, we may collect certain information by automated
                    means, such as cookies, web beacons and web server logs. The
                    information we collect in this manner may include your IP
                    address, unique device identifier, browser characteristics,
                    domain and other system settings, search queries, device
                    characteristics, operating system type, language
                    preferences, referring URLs, actions taken on our site,
                    dates and times of website visits, metadata and other
                    information associated with other files stored on your
                    device. Through these automated collection methods, we
                    obtain “clickstream data,” which is a log of the links and
                    other content on which a visitor clicks while browsing a
                    website. We may link certain data elements we have collected
                    through automated means, such as your browser information,
                    with other information we have obtained about you for the
                    purposes described below, and to let us know, for example,
                    whether you have opened an email we sent to you. While you
                    can change your cookies settings in your browser, such as to
                    refuse these cookies, please note, however, that without
                    cookies you may not be able to use all of the features of
                    our Website.
                  </p>
                  <p>
                    In addition, we may use local shared objects to collect and
                    store information about your preferences and how you
                    interact with our Websites and Apps. These technologies help
                    us recognize you, customize or personalize your experience,
                    items in your online cart between visits, and analyze the
                    use of our services and solutions to make them more useful
                    to you. These technologies also allow us to aggregate
                    demographic and statistical data and compilations of
                    information, which may include personal information, and
                    share this information with our business partners and
                    service providers that provide products and services to us.
                  </p>
                  <p>
                    We may also offer internet-based advertising programs on our
                    Website. These third-party services selectively display ads
                    based on browsing history. You can opt out of these services
                    by clicking on the links above.
                  </p>
                  <p>
                    We also may collect information about the location of the
                    mobile device or tablet used to access the Website
                    (“Location Data”). Location Data includes: (a) the location
                    of the mobile device or tablet derived from GPS or WiFi use;
                    (b) the IP address of the mobile device or tablet or
                    internet service used to access the Website; and (c) other
                    information made available by a user or others that
                    indicates the current or prior location of the user, such as
                    geotag information in photographs. We will inform you if a
                    specific Website, app or service collects precise Location
                    Data. If you do not want us to collect Location Data from
                    your device, please delete the app or disable the location
                    settings on your device or tablet. Note, however, that
                    disabling the location setting may affect your ability to
                    access and use that app.
                  </p>
                  <p>
                    When you connect with social media through the Website, you
                    may link with social media platforms and social media
                    plug-ins (e.g., the Facebook “Like” button, “Share to
                    Twitter” button) (collectively, “Social Media”). When
                    accessing services through Social Media accounts, we may
                    (depending on the applicable user privacy settings)
                    automatically have access to information provided to or
                    through the Social Media platform. We may collect and use
                    this information for the purposes described in this Policy
                    or at the time the information is collected.
                  </p>
                  <p>
                    Information collected directly by the third-party providers
                    of the features is subject to the privacy policies or
                    notices of these providers. We are not responsible for these
                    providers’ information and privacy practices.
                  </p>
                  <p>
                    Information we collect will only be used as described in
                    this Policy. If you choose not to provide certain
                    information, we may not be able to provide you with
                    requested products, services or information.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    How We Use the Information We Collect
                  </p>
                  <p>
                    We may use the information described above in one or more of
                    the following ways, and we may seek specific consent prior
                    to such use, depending on your jurisdiction:
                  </p>
                  <ul>
                    <li>to provide our Website and its contents to you;</li>
                    <li>
                      to provide requested products and services including, as
                      applicable, order confirmation, billing, and delivering
                      products or services;
                    </li>
                    <li>to process and track orders;</li>
                    <li>to respond to comments, questions, or concerns;</li>
                    <li>
                      to prevent and detect fraud and other unlawful activity,
                      claims and other liabilities;
                    </li>
                    <li>
                      to improve or develop new services and product offerings;
                    </li>
                    <li>
                      to identify, offer, and contact you with personalized
                      content and advertising, including newsletters, articles,
                      product or service alerts, new product or service
                      announcements, event invitations, and other information;
                    </li>
                    <li>
                      to conduct market research, surveys, and similar inquiries
                      to help us understand trends and customer needs across
                      product categories or customer groups;
                    </li>
                    <li>
                      to keep a record of our interactions with you if you place
                      an order or otherwise deal with our representatives over
                      the telephone or online;
                    </li>
                    <li>
                      to notify you of changes to our Website or any products or
                      services we offer;
                    </li>
                    <li>for marketing and promotional purposes;</li>
                    <li>
                      to verify and validate your identity to reduce credit risk
                      and manage collections;
                    </li>
                    <li>
                      to ensure that your experience is as easy and pleasant as
                      possible;
                    </li>
                    <li>
                      to carry out our obligations and enforce our rights; and
                    </li>
                    <li>
                      to comply with and enforce applicable legal requirements,
                      relevant industry standards, contractual obligations and
                      our policies.
                    </li>
                  </ul>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    We may also use the information in other ways for which we
                    describe at the time of collection.
                  </p>
                  <p>
                    In addition, we may use information collected through
                    cookies, web beacons, pixels, web server logs and other
                    automated means for purposes such as (a) customizing our
                    users’ visits to our Website, (b) delivering content
                    tailored to our users’ interests and the manner in which our
                    users browse our Website, and (c) managing our Website and
                    other aspects of our business. To the extent required by
                    applicable law, we will obtain your consent before
                    collecting information using cookies or similar automated
                    means.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    We may use third-party analytics services on our websites
                    and apps.
                  </p>
                  <p>
                    The analytics providers that administer these services use
                    technologies such as cookies, web server logs and web
                    beacons to help us analyze your use of our Website. The
                    information collected through these means (including IP
                    address) may be used by these analytics providers and other
                    relevant third parties who use the information, for example,
                    to evaluate use of the Website.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Interest-Based Advertising
                  </p>
                  <p>
                    On our Website, we may collect information about your online
                    activities for use in providing you with targeted
                    advertising about products and services tailored to your
                    individual interests. We also may obtain information for
                    this purpose from third-party websites on which our ads are
                    served. This section of our Policy provides details and
                    explains how to exercise your choices.
                  </p>
                  <p>
                    Through such ad networks, we can target our messaging to
                    users through demographic, interest-based and contextual
                    means. We may also use our own cookies to serve you relevant
                    advertising. We can track your online activities over time
                    by collecting information through automated means, including
                    through the use of first and third-party cookies, web server
                    logs, pixels and web beacons. The networks use this
                    information to show you advertisements that may be tailored
                    to your individual interests. The information our ad
                    networks may collect on our behalf includes data about your
                    visits to websites that serve our advertisements, such as
                    the pages or advertisements you view and the actions you
                    take on the websites. This data collection takes place both
                    on our Website and on third-party websites that participate
                    in these ad networks. This process also helps us track the
                    effectiveness of our marketing efforts.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Information We Share</p>
                  <p>
                    We may share personal information with service providers who
                    perform services on our behalf or to third parties in
                    connection with sales and marketing activity. Examples of
                    these service providers include entities that process credit
                    card payments, manage and reduce our credit risk, verify
                    information, fulfill orders, and provide web hosting,
                    analytics and marketing services. We also may share your
                    personal information with our affiliates and joint marketing
                    partners for the purposes described in this Policy.
                  </p>
                  <p>
                    In addition to the foregoing, we may release your personal
                    information if we are required to do so by law or court
                    order, to enforce or apply our Terms of Service or if we
                    believe in good faith that such release is necessary or
                    appropriate to protect the rights, property, or safety of
                    our company, customers, affiliates, or others.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Business Sales and Combinations
                  </p>
                  <p>
                    We may also share or transfer information, including your
                    personal information, in connection with the sale, transfer,
                    acquisition, merger, acquisition, divestiture,
                    restructuring, reorganization, dissolution, bankruptcy or
                    other change or ownership or control by us or any affiliated
                    company (in each case, whether in whole or in part). When
                    one of these events occurs, we will use reasonable efforts
                    to notify users before information is transferred or becomes
                    subject to different privacy practices.
                  </p>
                  <p>
                    We may also share information or data in a form that cannot
                    be used to uniquely identify or contact an individual person
                    (“non-personal information”). We may collect, use, transfer,
                    and disclose this non-personal information for any purposes.
                    Non-personal information that we collect may include
                    information regarding your activities on the Website, which
                    may be aggregated and used to help us provide more useful
                    information to our customers and to understand which parts
                    of the Website are of most interest.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    How We Protect Personal Information
                  </p>
                  <p>
                    We believe that we enforce commercially reasonable security
                    measures online and in our physical facilities to protect
                    against the loss, misuse, disclosure, or alteration of
                    personal information provided to us. While we strive to
                    safeguard your personal information, we cannot guarantee the
                    security of any information you disclose or transmit to us
                    and you use our site at your own risk.
                  </p>
                  <p>
                    If you receive an e-mail that looks like it is from us
                    asking you for your personal information, do not respond. We
                    will never request your password, username, credit card
                    information, or other personal information through e-mail.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Choice/Opt-Out</p>
                  <p>
                    We offer you certain choices in connection with the personal
                    information we collect about you, such as how we use the
                    information and how we communicate with you. To update your
                    preferences, ask us to remove you from our mailing lists,
                    exercise your rights regarding your personal information, or
                    submit a request, please contact us. You can also
                    unsubscribe from receiving our emails by following the
                    “Unsubscribe” link provided in our emails. We will honor
                    your request to remove you from our mailing lists, but there
                    may be a delay between submission of such request and actual
                    removal from our mailing lists, as these lists are generated
                    well in advance of our actual mailings. Opting out of
                    communications does not affect our communication with you
                    via telephone or email regarding your orders or other sales
                    or service transactions.
                  </p>
                  <p>
                    You may request access to the personal information we
                    maintain about you or request that we correct, amend, delete
                    or block the information by contacting us. You may withdraw
                    any consent you previously provided to us or object at any
                    time on legitimate grounds to the processing of your
                    personal information, and we will apply your preferences
                    going forward.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Text Messaging Services
                  </p>
                  <p>
                    When you sign up for one of our text messaging programs, you
                    are expressly consenting to receive text messages on your
                    cell phone or other mobile device about our products,
                    services, and/or programs which may be sent by automated
                    means. Text messages will only be sent to the mobile phone
                    number used to opt into the applicable text message program.
                    Details on how many messages you should expect to receive
                    are provided at the time of enrollment. In some instances,
                    you may have the opportunity to reply to a message, which
                    may result in additional response messages. Your consent to
                    enrollment in any text message program is not a condition of
                    purchase.
                  </p>
                  <p>
                    Message and data rates may apply. We do not charge a fee for
                    our text message programs and assume no responsibility for
                    charges by your carrier that you may incur when you sign up
                    to receive text messages from us. You may opt out of a text
                    message program at any time.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Children’s Privacy</p>
                  <p>
                    We are committed to protecting the privacy of children. The
                    Website is not directed to children and we do not knowingly
                    collect any personal information from children. If a child
                    under the age of 13 has provided us with personal
                    information online, we ask that a parent or guardian please
                    contact us. Please visit the FTC’s website at www.ftc.gov
                    for tips on protecting children’s privacy online.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Do Not Track Policy</p>
                  <p>
                    Some browsers have a “do not track” feature that lets you
                    tell websites that you do not want your online activities
                    tracked. At this time, the Website does not respond to web
                    browser “do not track” requests or similar signals that
                    users may employ.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Consent</p>
                  <p>
                    By providing information on or through a Website you are
                    consenting to the collection, use, sharing and disclosure of
                    such personal information as described in this Policy. You
                    may withdraw your consent at any time by following
                    contacting us.
                  </p>
                  <p>
                    In addition, your use of the Website constitutes consent (a)
                    to be contacted about your account through any contact
                    information you provide, including cell phone numbers, even
                    if the number is on a Do Not Call list, and even if you are
                    charged under your phone plan and (b) to the use of any
                    automatic telephone dialing system and/or a prerecorded
                    message when contacted.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Links to Third Party Sites and Services
                  </p>
                  <p>
                    This Website may contain links to third party websites
                    operated by individuals or companies unrelated to us. Please
                    be aware that we are not responsible for the privacy
                    practices of such third-party websites. We provide links to
                    these websites for your convenience only and you access them
                    at your own risk. We recommend that you review the privacy
                    policies and terms of use posted on and applicable to such
                    third-party websites prior to utilizing them.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Access and Retention</p>
                  <p>
                    If you have a Website account, you can log in to view and
                    update your account information. Please contact us with
                    questions or concerns, to request edits to your personal
                    information, or to have your personal information removed
                    from our database. We keep your personal information for as
                    long as we think is necessary or advisable, and we reserve
                    the right to retain it to the full extent not prohibited by
                    law.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Locations; Data Transfers
                  </p>
                  <p>
                    The Company is based in the United States. This Website is
                    intended for use only by persons located in the United
                    States. We make no claims that the Website or any of its
                    content is accessible or appropriate outside of the United
                    States. Access to the Website may not be legal by certain
                    persons or in certain countries. If you access the Website
                    from outside the United States, you do so on your own
                    initiative, at your sole risk, and you are responsible for
                    compliance with all applicable laws.
                  </p>
                  <p>
                    When you access this Website, you understand and acknowledge
                    that we may transfer, process, and store information about
                    you in the United States and other countries, both within
                    and outside of the European Economic Area. By providing us
                    with your information, you consent to the transfer to, and
                    to the processing and storage of your information in,
                    countries outside of your country of residence, which may
                    have different data protection laws than those in the
                    country in which you reside.
                  </p>
                </div>

                <div className={styles.section}>
                  <p
                    className={styles.sectionHeader}
                    style={{ fontWeight: 700 }}
                  >
                    Modifications to this Policy
                  </p>
                  <p>
                    We will modify this Policy if our privacy practices change.
                    We will notify you of such changes by posting the modified
                    version on our Website and indicating the date it was last
                    modified, and, if the changes are significant, we will
                    provide a more prominent notice (including by email in
                    certain instances). The date this Policy was last modified
                    is at the top of this page. Please periodically review this
                    Policy so that you are familiar with the current Policy and
                    aware of any changes.
                  </p>
                </div>
              </div>
            ) : (
              <div className={styles.privacyContainer}>
                <div className={styles.pageHeader}>Privacy Policy</div>

                <div className={styles.section}>
                  <p>
                    <i>Last revised October 1, 2020</i>
                  </p>
                  <p>
                    At Beyond Physician, Inc. we respect our customers’ privacy.
                    This privacy policy (“Policy”) applies to all activities,
                    including, but not limited to our websites, mobile sites,
                    services, applications, platforms and tools where this
                    Policy appears or is linked (collectively, our “Website”).
                    This Policy is to inform you about how we collect, use,
                    share, and protect personal information. By providing
                    information to us (by any means in correspondence, via our
                    Website or otherwise) you consent to our use of the
                    information you provide in accordance with this Policy. Any
                    reference to our Website includes its e-mail, text, live
                    chat, and other electronic messages. If you do not agree
                    with this Policy or our practices, you may not use our
                    Website. This Policy may change from time to time. Your
                    continued use of our Website constitutes your acceptance of
                    those changes. We encourage you to review this Policy
                    periodically.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Information We Collect</p>
                  <p>
                    We collect personal information about you in various ways,
                    such as when you provide it on our Website, through our apps
                    and social media channels, at events, through your
                    participation in surveys, via text messages or on the
                    telephone. The types of personal information we may collect
                    include:
                  </p>
                  <ul>
                    <li>
                      contact information (such as name, postal address, email
                      address, and mobile or other telephone number);
                    </li>
                    <li>
                      username and password if you have registered an account
                      with us;
                    </li>
                    <li>
                      payment information (such as your payment card number,
                      expiration date, authorization number or security code,
                      delivery address, and billing address);
                    </li>
                    <li>purchase, refund, and transaction information;</li>
                    <li>
                      customer service information (such as customer service
                      inquiries, comments and history);
                    </li>
                    <li>device information;</li>
                    <li>
                      photographs, comments and other content you provide;
                    </li>
                    <li>
                      information regarding your personal or professional
                      interests, date of birth, anniversary date, demographics
                      (such as gender, occupation, income and marital status),
                      experiences with our products and services, and contact
                      preferences;
                    </li>
                    <li>social media information;</li>
                    <li>
                      contact information you provide about friends or other
                      people you would like us to contact; and
                    </li>
                    <li>
                      information we may obtain from our third-party service
                      providers.
                    </li>
                  </ul>
                  <p>
                    In addition, when you visit our Website, open our emails,
                    use our apps or interact with our related tools, widgets or
                    plug-ins, we may collect certain information by automated
                    means, such as cookies, web beacons and web server logs. The
                    information we collect in this manner may include your IP
                    address, unique device identifier, browser characteristics,
                    domain and other system settings, search queries, device
                    characteristics, operating system type, language
                    preferences, referring URLs, actions taken on our site,
                    dates and times of website visits, metadata and other
                    information associated with other files stored on your
                    device. Through these automated collection methods, we
                    obtain “clickstream data,” which is a log of the links and
                    other content on which a visitor clicks while browsing a
                    website. We may link certain data elements we have collected
                    through automated means, such as your browser information,
                    with other information we have obtained about you for the
                    purposes described below, and to let us know, for example,
                    whether you have opened an email we sent to you. While you
                    can change your cookies settings in your browser, such as to
                    refuse these cookies, please note, however, that without
                    cookies you may not be able to use all of the features of
                    our Website.
                  </p>
                  <p>
                    In addition, we may use local shared objects to collect and
                    store information about your preferences and how you
                    interact with our Websites and Apps. These technologies help
                    us recognize you, customize or personalize your experience,
                    items in your online cart between visits, and analyze the
                    use of our services and solutions to make them more useful
                    to you. These technologies also allow us to aggregate
                    demographic and statistical data and compilations of
                    information, which may include personal information, and
                    share this information with our business partners and
                    service providers that provide products and services to us.
                  </p>
                  <p>
                    We may also offer internet-based advertising programs on our
                    Website. These third-party services selectively display ads
                    based on browsing history. You can opt out of these services
                    by clicking on the links above.
                  </p>
                  <p>
                    We also may collect information about the location of the
                    mobile device or tablet used to access the Website
                    (“Location Data”). Location Data includes: (a) the location
                    of the mobile device or tablet derived from GPS or WiFi use;
                    (b) the IP address of the mobile device or tablet or
                    internet service used to access the Website; and (c) other
                    information made available by a user or others that
                    indicates the current or prior location of the user, such as
                    geotag information in photographs. We will inform you if a
                    specific Website, app or service collects precise Location
                    Data. If you do not want us to collect Location Data from
                    your device, please delete the app or disable the location
                    settings on your device or tablet. Note, however, that
                    disabling the location setting may affect your ability to
                    access and use that app.
                  </p>
                  <p>
                    When you connect with social media through the Website, you
                    may link with social media platforms and social media
                    plug-ins (e.g., the Facebook “Like” button, “Share to
                    Twitter” button) (collectively, “Social Media”). When
                    accessing services through Social Media accounts, we may
                    (depending on the applicable user privacy settings)
                    automatically have access to information provided to or
                    through the Social Media platform. We may collect and use
                    this information for the purposes described in this Policy
                    or at the time the information is collected.
                  </p>
                  <p>
                    Information collected directly by the third-party providers
                    of the features is subject to the privacy policies or
                    notices of these providers. We are not responsible for these
                    providers’ information and privacy practices.
                  </p>
                  <p>
                    Information we collect will only be used as described in
                    this Policy. If you choose not to provide certain
                    information, we may not be able to provide you with
                    requested products, services or information.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    How We Use the Information We Collect
                  </p>
                  <p>
                    We may use the information described above in one or more of
                    the following ways, and we may seek specific consent prior
                    to such use, depending on your jurisdiction:
                  </p>
                  <ul>
                    <li>to provide our Website and its contents to you;</li>
                    <li>
                      to provide requested products and services including, as
                      applicable, order confirmation, billing, and delivering
                      products or services;
                    </li>
                    <li>to process and track orders;</li>
                    <li>to respond to comments, questions, or concerns;</li>
                    <li>
                      to prevent and detect fraud and other unlawful activity,
                      claims and other liabilities;
                    </li>
                    <li>
                      to improve or develop new services and product offerings;
                    </li>
                    <li>
                      to identify, offer, and contact you with personalized
                      content and advertising, including newsletters, articles,
                      product or service alerts, new product or service
                      announcements, event invitations, and other information;
                    </li>
                    <li>
                      to conduct market research, surveys, and similar inquiries
                      to help us understand trends and customer needs across
                      product categories or customer groups;
                    </li>
                    <li>
                      to keep a record of our interactions with you if you place
                      an order or otherwise deal with our representatives over
                      the telephone or online;
                    </li>
                    <li>
                      to notify you of changes to our Website or any products or
                      services we offer;
                    </li>
                    <li>for marketing and promotional purposes;</li>
                    <li>
                      to verify and validate your identity to reduce credit risk
                      and manage collections;
                    </li>
                    <li>
                      to ensure that your experience is as easy and pleasant as
                      possible;
                    </li>
                    <li>
                      to carry out our obligations and enforce our rights; and
                    </li>
                    <li>
                      to comply with and enforce applicable legal requirements,
                      relevant industry standards, contractual obligations and
                      our policies.
                    </li>
                  </ul>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    We may also use the information in other ways for which we
                    describe at the time of collection.
                  </p>
                  <p>
                    In addition, we may use information collected through
                    cookies, web beacons, pixels, web server logs and other
                    automated means for purposes such as (a) customizing our
                    users’ visits to our Website, (b) delivering content
                    tailored to our users’ interests and the manner in which our
                    users browse our Website, and (c) managing our Website and
                    other aspects of our business. To the extent required by
                    applicable law, we will obtain your consent before
                    collecting information using cookies or similar automated
                    means.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    We may use third-party analytics services on our websites
                    and apps.
                  </p>
                  <p>
                    The analytics providers that administer these services use
                    technologies such as cookies, web server logs and web
                    beacons to help us analyze your use of our Website. The
                    information collected through these means (including IP
                    address) may be used by these analytics providers and other
                    relevant third parties who use the information, for example,
                    to evaluate use of the Website.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Interest-Based Advertising
                  </p>
                  <p>
                    On our Website, we may collect information about your online
                    activities for use in providing you with targeted
                    advertising about products and services tailored to your
                    individual interests. We also may obtain information for
                    this purpose from third-party websites on which our ads are
                    served. This section of our Policy provides details and
                    explains how to exercise your choices.
                  </p>
                  <p>
                    Through such ad networks, we can target our messaging to
                    users through demographic, interest-based and contextual
                    means. We may also use our own cookies to serve you relevant
                    advertising. We can track your online activities over time
                    by collecting information through automated means, including
                    through the use of first and third-party cookies, web server
                    logs, pixels and web beacons. The networks use this
                    information to show you advertisements that may be tailored
                    to your individual interests. The information our ad
                    networks may collect on our behalf includes data about your
                    visits to websites that serve our advertisements, such as
                    the pages or advertisements you view and the actions you
                    take on the websites. This data collection takes place both
                    on our Website and on third-party websites that participate
                    in these ad networks. This process also helps us track the
                    effectiveness of our marketing efforts.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Information We Share</p>
                  <p>
                    We may share personal information with service providers who
                    perform services on our behalf or to third parties in
                    connection with sales and marketing activity. Examples of
                    these service providers include entities that process credit
                    card payments, manage and reduce our credit risk, verify
                    information, fulfill orders, and provide web hosting,
                    analytics and marketing services. We also may share your
                    personal information with our affiliates and joint marketing
                    partners for the purposes described in this Policy.
                  </p>
                  <p>
                    In addition to the foregoing, we may release your personal
                    information if we are required to do so by law or court
                    order, to enforce or apply our Terms of Service or if we
                    believe in good faith that such release is necessary or
                    appropriate to protect the rights, property, or safety of
                    our company, customers, affiliates, or others.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Business Sales and Combinations
                  </p>
                  <p>
                    We may also share or transfer information, including your
                    personal information, in connection with the sale, transfer,
                    acquisition, merger, acquisition, divestiture,
                    restructuring, reorganization, dissolution, bankruptcy or
                    other change or ownership or control by us or any affiliated
                    company (in each case, whether in whole or in part). When
                    one of these events occurs, we will use reasonable efforts
                    to notify users before information is transferred or becomes
                    subject to different privacy practices.
                  </p>
                  <p>
                    We may also share information or data in a form that cannot
                    be used to uniquely identify or contact an individual person
                    (“non-personal information”). We may collect, use, transfer,
                    and disclose this non-personal information for any purposes.
                    Non-personal information that we collect may include
                    information regarding your activities on the Website, which
                    may be aggregated and used to help us provide more useful
                    information to our customers and to understand which parts
                    of the Website are of most interest.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    How We Protect Personal Information
                  </p>
                  <p>
                    We believe that we enforce commercially reasonable security
                    measures online and in our physical facilities to protect
                    against the loss, misuse, disclosure, or alteration of
                    personal information provided to us. While we strive to
                    safeguard your personal information, we cannot guarantee the
                    security of any information you disclose or transmit to us
                    and you use our site at your own risk.
                  </p>
                  <p>
                    If you receive an e-mail that looks like it is from us
                    asking you for your personal information, do not respond. We
                    will never request your password, username, credit card
                    information, or other personal information through e-mail.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Choice/Opt-Out</p>
                  <p>
                    We offer you certain choices in connection with the personal
                    information we collect about you, such as how we use the
                    information and how we communicate with you. To update your
                    preferences, ask us to remove you from our mailing lists,
                    exercise your rights regarding your personal information, or
                    submit a request, please contact us. You can also
                    unsubscribe from receiving our emails by following the
                    “Unsubscribe” link provided in our emails. We will honor
                    your request to remove you from our mailing lists, but there
                    may be a delay between submission of such request and actual
                    removal from our mailing lists, as these lists are generated
                    well in advance of our actual mailings. Opting out of
                    communications does not affect our communication with you
                    via telephone or email regarding your orders or other sales
                    or service transactions.
                  </p>
                  <p>
                    You may request access to the personal information we
                    maintain about you or request that we correct, amend, delete
                    or block the information by contacting us. You may withdraw
                    any consent you previously provided to us or object at any
                    time on legitimate grounds to the processing of your
                    personal information, and we will apply your preferences
                    going forward.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Text Messaging Services
                  </p>
                  <p>
                    When you sign up for one of our text messaging programs, you
                    are expressly consenting to receive text messages on your
                    cell phone or other mobile device about our products,
                    services, and/or programs which may be sent by automated
                    means. Text messages will only be sent to the mobile phone
                    number used to opt into the applicable text message program.
                    Details on how many messages you should expect to receive
                    are provided at the time of enrollment. In some instances,
                    you may have the opportunity to reply to a message, which
                    may result in additional response messages. Your consent to
                    enrollment in any text message program is not a condition of
                    purchase.
                  </p>
                  <p>
                    Message and data rates may apply. We do not charge a fee for
                    our text message programs and assume no responsibility for
                    charges by your carrier that you may incur when you sign up
                    to receive text messages from us. You may opt out of a text
                    message program at any time.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Children’s Privacy</p>
                  <p>
                    We are committed to protecting the privacy of children. The
                    Website is not directed to children and we do not knowingly
                    collect any personal information from children. If a child
                    under the age of 13 has provided us with personal
                    information online, we ask that a parent or guardian please
                    contact us. Please visit the FTC’s website at www.ftc.gov
                    for tips on protecting children’s privacy online.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Do Not Track Policy</p>
                  <p>
                    Some browsers have a “do not track” feature that lets you
                    tell websites that you do not want your online activities
                    tracked. At this time, the Website does not respond to web
                    browser “do not track” requests or similar signals that
                    users may employ.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Consent</p>
                  <p>
                    By providing information on or through a Website you are
                    consenting to the collection, use, sharing and disclosure of
                    such personal information as described in this Policy. You
                    may withdraw your consent at any time by following
                    contacting us.
                  </p>
                  <p>
                    In addition, your use of the Website constitutes consent (a)
                    to be contacted about your account through any contact
                    information you provide, including cell phone numbers, even
                    if the number is on a Do Not Call list, and even if you are
                    charged under your phone plan and (b) to the use of any
                    automatic telephone dialing system and/or a prerecorded
                    message when contacted.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Links to Third Party Sites and Services
                  </p>
                  <p>
                    This Website may contain links to third party websites
                    operated by individuals or companies unrelated to us. Please
                    be aware that we are not responsible for the privacy
                    practices of such third-party websites. We provide links to
                    these websites for your convenience only and you access them
                    at your own risk. We recommend that you review the privacy
                    policies and terms of use posted on and applicable to such
                    third-party websites prior to utilizing them.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>Access and Retention</p>
                  <p>
                    If you have a Website account, you can log in to view and
                    update your account information. Please contact us with
                    questions or concerns, to request edits to your personal
                    information, or to have your personal information removed
                    from our database. We keep your personal information for as
                    long as we think is necessary or advisable, and we reserve
                    the right to retain it to the full extent not prohibited by
                    law.
                  </p>
                </div>

                <div className={styles.section}>
                  <p className={styles.sectionHeader}>
                    Locations; Data Transfers
                  </p>
                  <p>
                    The Company is based in the United States. This Website is
                    intended for use only by persons located in the United
                    States. We make no claims that the Website or any of its
                    content is accessible or appropriate outside of the United
                    States. Access to the Website may not be legal by certain
                    persons or in certain countries. If you access the Website
                    from outside the United States, you do so on your own
                    initiative, at your sole risk, and you are responsible for
                    compliance with all applicable laws.
                  </p>
                  <p>
                    When you access this Website, you understand and acknowledge
                    that we may transfer, process, and store information about
                    you in the United States and other countries, both within
                    and outside of the European Economic Area. By providing us
                    with your information, you consent to the transfer to, and
                    to the processing and storage of your information in,
                    countries outside of your country of residence, which may
                    have different data protection laws than those in the
                    country in which you reside.
                  </p>
                </div>

                <div className={styles.section}>
                  <p
                    className={styles.sectionHeader}
                    style={{ fontWeight: 700 }}
                  >
                    Modifications to this Policy
                  </p>
                  <p>
                    We will modify this Policy if our privacy practices change.
                    We will notify you of such changes by posting the modified
                    version on our Website and indicating the date it was last
                    modified, and, if the changes are significant, we will
                    provide a more prominent notice (including by email in
                    certain instances). The date this Policy was last modified
                    is at the top of this page. Please periodically review this
                    Policy so that you are familiar with the current Policy and
                    aware of any changes.
                  </p>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Privacy;
