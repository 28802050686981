import React, { useState, useContext, useEffect } from "react";

import GlobalContext from 'contexts/Global.context';

import { fetchMyCards, deleteMyCard } from 'services/PaymentService';

import { Row, Col, Modal } from 'react-bootstrap';
import Button from 'components/Form/Button/Button';

import { Card } from 'interfaces/Card.types';

import styles from './MyCards.module.scss';

interface Props {
  refresh: number;
}

const MyCards: React.FC<Props> = ({ refresh = 0 }) => {
  const { userDetails: { paymentId } } = useContext(GlobalContext);
  const [cards, setCards] = useState<Card[]>([]);
  const [deletingCard, setDeletingCard] = useState<boolean>(false);
  const [deleteCardPrompt, setDeleteCardPrompt] = useState<string>(null);

  useEffect(() => {
    const fetchCards = async () => {
      const { error, cards } = await fetchMyCards();
      if (!error) {
        setCards(cards);
      }
    }
    if (paymentId) {
      fetchCards();
    }
  }, [paymentId, refresh]);

  const deleteCard = async (cardId: string) => {
    setDeletingCard(true);
    const { error } = await deleteMyCard(cardId);
    if (!error) {
      setDeleteCardPrompt(null);
      setCards(cards.filter(card => (card.id !== cardId)));
    }
    setDeletingCard(false);
  };

  return (
    <Row>
      <Col lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }} md={12} sm={12}>
        <div className={styles.savedCardList}>
          {!!cards.length && cards.map(card => (
            <div key={card.id} className={styles.list}>
              <div className={styles.numberType}>
                <div className={styles.cardNumber}>*****{card.last4}</div>
                <div className={styles.cardType}>CreditCard</div>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  type="small btnGrayDark"
                  label="Remove"
                  onClick={() => setDeleteCardPrompt(card.id)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="delete-card-modal">
          <Modal
            show={deleteCardPrompt !== null}
            onHide={() => setDeleteCardPrompt(null)}
            aria-labelledby="contained-modal-title-md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="delete-card">
                Are you sure you want to delete this credit card from your account?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="primary" onClick={() => deleteCard(deleteCardPrompt)} label="Delete" disabled={deletingCard} />
            </Modal.Footer>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(MyCards);