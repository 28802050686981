import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";

// Components
import Course from "components/Course/Course";

// Context
import DashboardContext from "../../contexts/Dashboard.context";

const DashboardTopCourses = () => {
  const { 
    topCourses,
    isLoading,
  } = useContext(DashboardContext);

  return (
    <Row>
      {!isLoading ? topCourses.map((course, index) => {
        return (
          <Col xl="3" lg="3" md="6" xs="12" key={index}>
            <Course
              id={course.id}
              image={course.banner}
              category={course.category}
              title={course.title}
              description={course.description}
              count={course.registrations}
              isOffsite={course.is_offsite_affiliate}
              externalLink={course.external_link}
            />
          </Col>
        );
      }) : <div className="loader"><span></span><span></span></div>}
    </Row>
  );
};

export default React.memo(DashboardTopCourses);
