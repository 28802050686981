import React, { Fragment, useContext, useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import History from 'utils/History';

// Context
import HomeContext from '../../contexts/Home.context';
import GlobalContext from 'contexts/Global.context';

// Components
import Select from 'components/Form/Select/Select';
import Course from "components/Course/Course";
import Button from "../../../../components/Form/Button/Button";

// Constants
import { INDUSTRIES, COST_OPTIONS } from "constants/Constants";

//SCSS;
import styles from "./TopCourses.module.scss";

const TOP_COURSES = 8;
const ORDER_BY = 'registrations';

const TopCourses = () => {
  const { courses, totalCourses, getCourses, isLoading } = useContext(HomeContext);
  const { categories, variantDetails } = useContext(GlobalContext);
  const [ industry, setIndustry ] = useState<string>('');
  const [ cost, setCost ] = useState<string>('');
  const [ category, setCategory ] = useState<string>('');

  useEffect(() => {
    getCourses({ category, industry, cost }, TOP_COURSES, ORDER_BY);
  }, [category, cost, industry, getCourses]);

  const viewAllCourses = () => {
    History.push(`/courses`);
  }

  return (
    <Fragment>
      <div className={styles.topCoursesWapper}>
        <Container>
          <Row>
            <Col>
              <div className={styles.sectionHeading}>Top Courses</div>
            </Col>
          </Row>

          <div className={styles.tabsParent}>
            <div className={styles.filterSection}>
              <Row className="align-items-center">
                <Col sm="auto">
                  <div className={styles.filterLabel}>Filter By</div>
                </Col>
                <Col sm="auto">
                  <Select
                    id="state"
                    name="state"
                    onChange={(e) => setCategory(e.target.value)}
                    selectItems={categories}
                    value={category}
                    placeholder="Select Category"
                  />
                </Col>
                {!variantDetails?.site_short_title.includes(
                  "BeyondPhysician"
                ) && (
                  <Col sm="auto">
                    <Select
                      id="industry"
                      name="industry"
                      onChange={(e) => setIndustry(e.target.value)}
                      selectItems={INDUSTRIES}
                      value={industry}
                      placeholder="Select Industry"
                    />
                  </Col>
                )}
                <Col sm="auto">
                  <Select
                    id="cost"
                    name="cost"
                    onChange={(e) => setCost(e.target.value)}
                    selectItems={COST_OPTIONS}
                    value={cost}
                    placeholder="Select Cost"
                  />
                </Col>
              </Row>
            </div>
            <div className={styles.tabContent}>
              <div className="tab-item-wrapper">
                <Row className={styles.tabRow}>
                  {!isLoading &&
                    !!courses.length &&
                    courses.map((course, key) => {
                      return (
                        <Col key={key} xl="3" lg="3" md="6" xs="12">
                          <Course
                            id={course.id}
                            image={course.banner}
                            category={course.category}
                            title={course.title}
                            description={course.description}
                            count={course.registrations}
                            isOffsite={course.is_offsite_affiliate}
                            externalLink={course.external_link}
                          />
                        </Col>
                      );
                    })}
                  {isLoading && (
                    <div className="loader">
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </Row>
              </div>
            </div>
            {!!totalCourses && (
              <span className={styles.resultsCount}>
                {totalCourses} Results
              </span>
            )}
          </div>

          {courses.length ? (
            <Row>
              <Col className="text-center">
                <div className={styles.btnOuter}>
                  <Button
                    onClick={viewAllCourses}
                    label="View All"
                    type="large ghostBtn ghostBtnGray"
                  />
                </div>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </Fragment>
  );
};

export default React.memo(TopCourses);
