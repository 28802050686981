import React, { useState, useContext, useEffect } from "react";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import GlobalContext from 'contexts/Global.context';

import { fetchMyCards, deleteMyCard } from 'services/PaymentService';

import { Row, Col, Container, Modal } from 'react-bootstrap';
import { Loader } from 'components/Loader/Loader';
import Button from 'components/Form/Button/Button';
import Radio from 'components/Form/Radio/Radio';
import AddCard from "./AddCard";

import { Card } from 'interfaces/Card.types';

import { APP_CONSTANTS } from 'constants/Constants';

import styles from './Cards.module.scss';

const { stripeKey } = APP_CONSTANTS;

const STRIPE_LOAD = loadStripe(stripeKey);

interface Props {
	onCardSelected: (cardId: string) => void;
};

const Cards: React.FC<Props> = ({ onCardSelected }) => {
	const { userDetails: { paymentId } } = useContext(GlobalContext);
	const [cards, setCards] = useState<Card[]>([]);
	const [selectedCard, setSelectedCard] = useState<string>(null);
	const [fetchingCards, setFetchingCards] = useState<boolean>(false);
	const [deletingCard, setDeletingCard] = useState<boolean>(false);
	const [showDeleteCardPrompt, setShowDeleteCardPrompt] = useState<boolean>(false);

	useEffect(() => {
		const fetchCards = async () => {
			setFetchingCards(true);
			const { error, cards } = await fetchMyCards();
			if (!error) {
				setCards(cards);
				if (cards.length) {
					setSelectedCard(cards[0].id);
				}
			}
			setFetchingCards(false);
		}
		if (paymentId) {
			fetchCards();
		}
	}, [paymentId]);

	useEffect(() => {
		onCardSelected(selectedCard);
	}, [selectedCard, onCardSelected]);

	const deleteCard = async (cardId: string) => {
		setDeletingCard(true);
		const { error } = await deleteMyCard(cardId);
		if (!error) {
			setShowDeleteCardPrompt(false);
			const newCards = cards.filter(card => (card.id !== cardId));
			setCards(newCards);
			if (newCards.length) {
				setSelectedCard(newCards[0].id);
			}
			else {
				setSelectedCard(null);
			}
		}
		setDeletingCard(false);
	};

	const refreshCards = async () => {
		setFetchingCards(true);
		const { error, cards } = await fetchMyCards();
		if (!error) {
			setCards(cards);
			if (cards.length) {
				setSelectedCard(cards[0].id);
			}
		}
		setFetchingCards(false);
	};

	return (
		<div className={styles.userCards}>
			<Elements stripe={STRIPE_LOAD}>
				<AddCard onSuccess={refreshCards} />
				{fetchingCards && <Loader />}
				{!fetchingCards && !!cards.length && cards.map(card => (
					<Container className={styles.cardWrapper} key={card.id}>
						<Row>
							<Col className="text-left" xl={6} lg={6} md={6} sm={6}>
								<Radio
									id={card.id}
									name={card.id}
									className={styles.cardNumberLabel}
									label={`${card.brand} XXXX-XXXX-XXXX-${card.last4}`}
									isSelected={card.id === selectedCard}
									onChange={(e) => setSelectedCard(card.id)}
								/>
							</Col>

							<Col className={styles.deleteBtn} xl={6} lg={6} md={6} sm={6}>
								<Button
									type="dangerBtnSm"
									onClick={() => setShowDeleteCardPrompt(true)}
									label="Delete"
								/>
							</Col>
						</Row>
						<div className="delete-card-modal">
							<Modal
								show={showDeleteCardPrompt}
								onHide={() => setShowDeleteCardPrompt(false)}
								aria-labelledby="contained-modal-title-md"
							>
								<Modal.Header closeButton>
									<Modal.Title>Delete Card</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className="delete-card">
										Are you sure you want to delete this credit card from your account?
		              </div>
								</Modal.Body>
								<Modal.Footer>
									<Button type="primary" onClick={() => deleteCard(card.id)} label="Delete" disabled={deletingCard} />
								</Modal.Footer>
							</Modal>
						</div>
					</Container>
				))}
			</Elements>
		</div>
	);
};

export default React.memo(Cards);