import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";

// SCSS
import styles from "./CourseLessons.module.scss";

// Components
import Radio from "components/Form/Radio/Radio";

// Types
import { Modules } from "interfaces/Course.types";

// Props
interface Props {
  activeLesson: number;
  lessons: Modules[];
  completedLessons: number;
  disabled?: boolean;
  setActiveLesson: (index: number) => void;
}

const CourseLessons: React.FC<Props> = ({
  activeLesson,
  lessons,
  completedLessons,
  setActiveLesson,
  disabled,
}) => {

  const renderLabel = (lesson: Modules, index: number) => {
    return (
      <div className={`${styles.lessonDetails} ${activeLesson === index ? styles.active : ''}`}>
        <div className={styles.lessonNo}>Lesson {index+1}</div>
        <div className={styles.heading}>{lesson.name}</div>
        <div className={styles.duration}>{lesson.duration}</div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className={styles.lessonsItem}>
        <div className={styles.lesson}>
          {lessons?.map((lesson, index) => {
            return (
              <Row key={index}>
                <Col>
                  <Radio onChange={() => setActiveLesson(index)} disabled={index > completedLessons - 1 || disabled} isCompleted={index <= completedLessons - 1} isSelected={activeLesson === index} label={renderLabel(lesson, index)} name="lesson Menu" id={lesson.name} />
                </Col>
              </Row>
            )
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(CourseLessons);
