import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import numeral from "numeral";

import History from "utils/History";

import OfficialPartner from "assets/svgs/officialicon.svg";

// SCSS
import styles from "./CourseIntro.module.scss";

// Components
import Button from "components/Form/Button/Button";

// Types
import { Modules } from "interfaces/Course.types";

interface Props {
  quiz?: string;
  description: string;
  cost?: number;
  title?: string;
  category?: string;
  enterCourse?: () => void;
  activeLesson: number;
  lesson: Modules;
  id: string;
  isRegistered?: boolean;
  isLoading: boolean;
  isOffsite: boolean;
  externalLink?: string;
  variantDetails: any;
}

const gotoQuiz = (id) => {
  History.push(`/dashboard/quizzes/${id}`);
};

const CourseIntro: React.FC<Props> = ({
  quiz,
  description,
  cost,
  title,
  category,
  enterCourse,
  activeLesson,
  lesson,
  id,
  isRegistered,
  isLoading,
  isOffsite,
  externalLink,
  variantDetails,
}) => {

  const DEFAULT_PHOTO = `${variantDetails?.assets_url}/header_logo_thumb.svg`;

  const getEntryLabel = (): string => {
    if (isRegistered) {
      return "Continue To Course";
    } else if (cost > 0) {
      return "Purchase Course";
    }
    return "Enter Course";
  };

  return (
    <Fragment>
      <div className={styles.courseIntroWrapper}>
        <div className={styles.courseCategory}>{category}</div>
        <div className={styles.aboutWrapper}>
          <Row>
            <Col md={6}>
              <div className={styles.courseHeading}>{title}</div>
              {false && (
                <div className={styles.subCourseHeading}>
                  A deep dive into the future of lorem ipsum and further
                  research into analog lorem.
                </div>
              )}
              <div className={styles.coursePrice}>
                {numeral(cost).format("$0.00")}
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.btnWrapper}>
                <Button
                  disabled={isLoading}
                  onClick={enterCourse}
                  label={getEntryLabel()}
                  type="large branding"
                />
                {isOffsite && externalLink && <div className={styles.partnerContent}><img src={OfficialPartner} alt="official partner content" />Official Partner Content</div>}
                {quiz && (
                  <Button
                    onClick={() => gotoQuiz(quiz)}
                    label="Take Quiz Now"
                    type="large ghostBtn"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12}>
            <div className={styles.courseInstructor}>
              <div className={styles.instHeading}>Meet Your Instructor</div>
              <div className={styles.intructorDetail}>
                <div className={styles.img}>
                  <img
                    alt="Instructor"
                    src={lesson?.instructor_photo || DEFAULT_PHOTO}
                  />
                </div>
                <div className={styles.insDetailInner}>
                  <div
                    className={styles.instName}
                  >{`${lesson?.first_name} ${lesson?.last_name}`}</div>
                  <div className={styles.shortDes}>
                    {lesson?.instructor_bio}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.aboutCourse}>
              <div className={styles.description}>{description}</div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default React.memo(CourseIntro);
