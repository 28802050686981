import React from 'react';
import { CourseList } from 'interfaces/CourseList.types';
import { QuizList } from 'interfaces/QuizList.types';

interface dashboardInterface {
  topCourses: CourseList[];
  pendingCourses: CourseList[];
  pendingQuizzes: QuizList[];
  completedCourses: CourseList[];
  completedQuizzes: QuizList[];
  completedCoursesCount: number;
  completedQuizzesCount: number;
  incompleteQuizzesCount: number;
  incompleteCoursesCount?: number;
  isLoading: boolean;
  setTopCourses: (courses: CourseList[]) => void;
  setPendingCourses: (courses: CourseList[]) => void;
  setCompletedCourses: (courses: CourseList[]) => void;
  setCompletedQuizzes: (quizzes: QuizList[]) => void;
  setPendingQuizzes: (quizzes: QuizList[]) => void;
  setCompletedQuizzesCount: (quizzes: number) => void;
  setCompletedCoursesCount: (courses: number) => void;
  setIncompleteCoursesCount: (courses: number) => void;
  setIncompleteQuizzesCount: (quizzes: number) => void;
  setIsLoading: (loading: boolean) => void;
  loadDashboard: () => void;
}

const dashboard: dashboardInterface = {
  topCourses: [],
  pendingCourses: [],
  pendingQuizzes: [],
  completedCourses: [],
  completedQuizzes: [],
  isLoading: false,
  completedCoursesCount: 0,
  completedQuizzesCount: 0,
  incompleteQuizzesCount: 0,
  incompleteCoursesCount: 0,
  setIsLoading: () => {},
  setTopCourses: () => {},
  setPendingCourses: () => {},
  setPendingQuizzes: () => {},
  setCompletedCourses: () => {},
  setCompletedQuizzes: () => {},
  setCompletedCoursesCount: () => {},
  setIncompleteCoursesCount: () => {},
  setCompletedQuizzesCount: () => {},
  setIncompleteQuizzesCount: () => {},
  loadDashboard: () => {}
};

export default React.createContext(dashboard);
