import React from 'react';
import SVG from "react-inlinesvg";

interface Props {
	title?: string;
	src: string;
	className?: string;
}

const Image: React.FC<Props> = ({
	title,
	src,
	className
}) => {
	return (
		src.toLowerCase().indexOf("svg") > -1 ? <SVG
      baseURL="/"
      cacheRequests={true}
      className={className}
      description={title}
      src={src}
      loader={<span>Loading...</span>}
      title={title}
    /> :
    <img
      className={className}
      src={src}
      title={title}
      alt={title}
    />
	);
};

export default React.memo(Image);