import React, { useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Components
import { Container, Row, Col, Alert } from 'react-bootstrap';
import Input from 'components/Form/Input/Input';
import Button from 'components/Form/Button/Button';

// Constants
import { APP_CONSTANTS, ROUTES } from 'constants/Constants';
// Context
import GlobalContext from 'contexts/Global.context';

// Services
import { resetPasswordService } from 'services/UserService';

// Styles
import styles from './ResetPassword.module.scss';

interface Passwords {
  password: string;
  confirmPassword: string;
}

const {
  errorConstants: {
    PASSWORD_NOT_ENTERED_ERROR,
    PASSWORD_CHAR_LENGTH_ERROR,
    CONFIRM_PASSWORD_ERROR,
    PASSWORDS_MISMATCH_ERROR,
  },
} = APP_CONSTANTS;

const ResetPassword = () => {
  const { token } = useParams();
  const { variantDetails } = useContext(GlobalContext);

  const [passwords, setPasswords] = useState<Passwords>({
    password: '',
    confirmPassword: '',
  });

  const [formErrors, setFormErrors] = useState<Passwords>({
    password: '',
    confirmPassword: '',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);

  const [apiError, setApiError] = useState<string>('');

  /**
   * Handler that gets called when text in input field changes
   * @param {Object} event The event object
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'password') {
      if (!value) {
        setFormErrors({
          ...formErrors,
          [name]: PASSWORD_NOT_ENTERED_ERROR,
        });
      } else {
        if (value.length < 8) {
          setFormErrors({
            ...formErrors,
            [name]: PASSWORD_CHAR_LENGTH_ERROR,
          });
        } else {
          setFormErrors({
            ...formErrors,
            [name]: '',
          });
        }
      }
    }

    if (name === 'confirmPassword') {
      if (!value) {
        setFormErrors({
          ...formErrors,
          [name]: CONFIRM_PASSWORD_ERROR,
        });
      } else {
        if (value !== passwords.password) {
          setFormErrors({
            ...formErrors,
            [name]: PASSWORDS_MISMATCH_ERROR,
          });
        } else {
          setFormErrors({
            ...formErrors,
            [name]: '',
          });
        }
      }
    }

    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  /**
   * Function to disable/enable the submit button
   */
  const isSubmitDisabled = () => {
    const { password, confirmPassword } = passwords;
    if (password && password.length >= 8 && password === confirmPassword) {
      return false;
    }

    return true;
  };

  /**
   * Function that gets called once user requests for changing the password
   */
  const changePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setApiError('');
    setLoading(true);
    const response = await resetPasswordService(token, passwords.password);

    if (!response.ok) {
      setApiError(await response.clone().text());
    } else {
      const apiResponse = await response.json();
      if (apiResponse.result === 'success') {
        setIsPasswordChanged(true);
      }
    }
    setLoading(false);
    setPasswords({
      ...passwords,
      password: '',
      confirmPassword: '',
    });
  };

  return (
    <>
      <Helmet>
        <title>{variantDetails?.site_title} | Reset Password</title>
      </Helmet>
      <Container className={`${styles.authPage}`} fluid>
        <Container className={styles.authPageWrap}>
          <Row className="justify-content-md-center">
            <Col className={`${styles.authForm}`} xl={6} lg={6} md={12} sm={12}>
              <div className={styles.authFormWrap}>
                <div className={styles.authFormTitle}>Reset Password</div>
                <form onSubmit={changePassword}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Password</div>
                    <Input
                      name="password"
                      type="password"
                      value={passwords.password}
                      onChange={handleInputChange}
                    />
                    {formErrors.password && (
                      <div className={styles.error}>{formErrors.password}</div>
                    )}
                  </div>

                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Confirm Password</div>
                    <Input
                      name="confirmPassword"
                      type="password"
                      value={passwords.confirmPassword}
                      onChange={handleInputChange}
                    />
                    {formErrors.confirmPassword && (
                      <div className={styles.error}>
                        {formErrors.confirmPassword}
                      </div>
                    )}
                  </div>

                  <div className={styles.authButton}>
                    {apiError && <Alert variant="danger">{apiError}</Alert>}
                    {isPasswordChanged && (
                      <Alert variant="success">
                        Your password is changed successfully, please click{' '}
                        <Link to={ROUTES.LOGIN}>here</Link> to login.
                      </Alert>
                    )}
                    <Button
                      type="large w-100"
                      buttonType="submit"
                      label={loading ? 'loading...' : 'Change Password'}
                      disabled={loading || isSubmitDisabled()}
                    />
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default React.memo(ResetPassword);
