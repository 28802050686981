import React from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

// Components
import Button from 'components/Form/Button/Button';
import Input from 'components/Form/Input/Input';
import FileUpload from 'components/Form/FileUpload/FileUpload';
import Select from 'components/Form/Select/Select';

import { Multiselect } from 'multiselect-react-dropdown';

// Interfaces
import { UserDetailsType } from 'interfaces/Register.types';

// Constants
import { FILE_TYPE, ROUTES } from 'constants/Constants';
import STATES from 'constants/States';
import COPY_OVERRIDES from "config/CopyOverrides";
import {
  GOALS,
  SPECIALITIES,
  QUALIFICATIONS,
  FELLOWSHIPS,
  FELLOWSHIP_SUBSPECIALITIES,
} from "constants/Educations";

import CONFIGURATION from 'config/Configuration';

// SCSS
import styles from './UserDetailsForm.module.scss';
import { Category } from 'interfaces/Category.types';

interface Props {
  categories?: Array<Category>;
  userDetails: UserDetailsType;
  userDetailsErrors: any;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCategorySelect: (list: Array<any>, item: any) => void;
  handleMultiSelect: (list: Array<any>, key: string) => void;
  handleWebsiteInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAdditionalLinksChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePhotoChange: (photo: File | null) => void;
  handleResumeChange: (resume: File | null) => void;
  isSubmitDisabled: boolean;
  handleSubmit: () => void;
  loading: boolean;
  apiError?: string;
}

const renderTooltipCost = (props: any) => (
  <Tooltip
    className={styles.tooltipContainer}
    id="button-tooltipCost"
    {...props}
  >
    Your topics of interest allows us to suggest relevant Courses and
    Credentials. Select an unlimited number of topics. Suggest a new topic to
    learn about or develop a course on by clicking the link.
  </Tooltip>
);

const HTTP_PLACEHOLDER = 'http://';

const UserDetailsForm: React.FunctionComponent<Props> = ({
  categories,
  userDetails,
  userDetailsErrors,
  handleInputChange,
  handleCategorySelect,
  handleMultiSelect,
  handleWebsiteInputChange,
  handleAdditionalLinksChange,
  handlePhotoChange,
  isSubmitDisabled,
  handleSubmit,
  loading,
  apiError,
}: Props) => {
  return (
    <Container className={`mb-5 ${styles.registerContinued}`} fluid>
      <Container className="p-0">
        <Row className="my-5">
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerTitle}>Sign Up</div>
            <div className={styles.registerSubTitle}>
              Last step before you complete your profile.
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerIntro}>
              Almost finished, just need some final information so we can create
              a proper profile.
            </div>
            <div className={`${styles.requiredFieldsNote} text-right`}>
              <span>* </span>= Required Fields
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Personal Information</div>
              <div className={styles.registerSubLabel}>
                Tell us about yourself.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    First Name
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="firstName"
                    type="text"
                    value={userDetails.firstName}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.firstName ? styles.errorInput : ""
                    }
                  />
                  {userDetailsErrors.firstName && (
                    <div className={styles.error}>
                      {userDetailsErrors.firstName}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Last Name
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="lastName"
                    type="text"
                    value={userDetails.lastName}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.lastName ? styles.errorInput : ""
                    }
                  />
                  {userDetailsErrors.lastName && (
                    <div className={styles.error}>
                      {userDetailsErrors.lastName}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    City
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="city"
                    type="text"
                    value={userDetails.city}
                    onChange={handleInputChange}
                    className={userDetailsErrors.city ? styles.errorInput : ""}
                  />
                  {userDetailsErrors.city && (
                    <div className={styles.error}>{userDetailsErrors.city}</div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    State
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Select
                    id="state"
                    selectItems={STATES}
                    onChange={handleInputChange}
                    name="state"
                    value={userDetails.state}
                  />
                  {userDetailsErrors.state && (
                    <div className={styles.error}>
                      {userDetailsErrors.state}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Zip
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="zip"
                    type="text"
                    value={userDetails.zip || ""}
                    onChange={handleInputChange}
                    className={userDetailsErrors.zip ? styles.errorInput : ""}
                  />
                  {userDetailsErrors.zip && (
                    <div className={styles.error}>{userDetailsErrors.zip}</div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Upload Your Photo</div>
                  <FileUpload
                    fileType={FILE_TYPE.IMAGE}
                    fileSpecs={["5mb max", "500px wide", "500px tall"]}
                    onFileChange={handlePhotoChange}
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Professional Classification
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Select
                    id="professionalClassification"
                    selectItems={QUALIFICATIONS}
                    onChange={handleInputChange}
                    name="professionalClassification"
                    value={userDetails.professionalClassification}
                  />
                  {userDetailsErrors.professionalClassification && (
                    <div className={styles.error}>
                      {userDetailsErrors.professionalClassification}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Professional Classification Other (Only if not listed above)
                  </div>
                  <Input
                    name="professionalClassificationOther"
                    type="text"
                    value={userDetails.professionalClassificationOther}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.professionalClassificationOther
                        ? styles.errorInput
                        : ""
                    }
                  />
                  {userDetailsErrors.professionalClassificationOther && (
                    <div className={styles.error}>
                      {userDetailsErrors.professionalClassificationOther}
                    </div>
                  )}
                </div>
              </Col>
              {/* <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    About
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <TextArea
                    name="about"
                    value={userDetails.about}
                    onChange={handleInputChange}
                    className={userDetailsErrors.about ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.about && (
                    <div className={styles.error}>
                      {userDetailsErrors.about}
                    </div>
                  )}
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Goals & Interests</div>
              <div className={styles.registerSubLabel}>
                How do you intend to use the site? This will help us provide you
                with the best experience. This information can be edited/updated
                at any time.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Goals (Check all that apply)
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Multiselect
                    options={GOALS || []}
                    isObject={true}
                    displayValue="label"
                    closeOnSelect={false}
                    showCheckbox
                    onSelect={(list) => handleMultiSelect(list, "goals")}
                    onRemove={(list) => handleMultiSelect(list, "goals")}
                  />
                  {userDetailsErrors.goals && (
                    <div className={styles.error}>
                      {userDetailsErrors.goals}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Other Goals (only if not listed above)
                  </div>
                  <Input
                    name="otherGoals"
                    type="text"
                    value={userDetails.otherGoals}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.otherGoals ? styles.errorInput : ""
                    }
                  />
                  {userDetailsErrors.otherGoals && (
                    <div className={styles.error}>
                      {userDetailsErrors.otherGoals}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Topics of Interest
                    <span className={styles.requiredField}> *</span>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipCost}
                    >
                      <div className={styles.infoHover}>i</div>
                    </OverlayTrigger>
                    <span className={styles.shortMess}>
                      <a
                        href={
                          CONFIGURATION?.new_category_link ||
                          "https://musiccareermap.com/category-submission"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        (If you don&apos;t see your topic listed, click here
                        to request it.)
                      </a>
                    </span>
                  </div>
                  <Multiselect
                    options={categories || []}
                    isObject={true}
                    displayValue="name"
                    showCheckbox
                    onSelect={handleCategorySelect}
                    onRemove={handleCategorySelect}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>
                Professional Information
              </div>
              <div className={styles.registerSubLabel}>
                Tell us about your career.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Residency
                    <div>
                      (Please choose one if applicable, and{" "}
                      <a
                        href="http://beyondphysician.org/residency-category-submission"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        click here
                      </a>{" "}
                      if your preference is not listed.)
                    </div>
                  </div>
                  <Select
                    id="speciality"
                    selectItems={SPECIALITIES}
                    onChange={handleInputChange}
                    name="speciality"
                    value={userDetails.speciality}
                  />
                  {userDetailsErrors.speciality && (
                    <div className={styles.error}>
                      {userDetailsErrors.speciality}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Fellowship Category{" "}
                    <div>
                      (Please choose one, if applicable.{" "}
                      <a
                        href={
                          COPY_OVERRIDES?.profile?.submit_fellowship_link ||
                          "https://musiccareermap.com/category-submission"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              COPY_OVERRIDES?.profile?.submit_fellowship_text ||
                              "Click here",
                          }}
                        ></span>
                      </a>{" "}
                      if your option is not listed.)
                    </div>
                  </div>
                  <Select
                    id="fellowship"
                    selectItems={FELLOWSHIPS}
                    onChange={handleInputChange}
                    name="fellowship"
                    value={userDetails.fellowship}
                  />
                  {userDetailsErrors.fellowship && (
                    <div className={styles.error}>
                      {userDetailsErrors.fellowship}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Fellowship Subspecialty{" "}
                    <div>
                      (After selecting your Category above, please choose one
                      subcategory.{" "}
                      <a
                        href={
                          COPY_OVERRIDES?.profile?.submit_fellowship_sub_link ||
                          "https://musiccareermap.com/category-submission"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              COPY_OVERRIDES?.profile?.submit_fellowship_text ||
                              "Click here",
                          }}
                        ></span>
                      </a>{" "}
                      if your option is not listed.)
                    </div>
                  </div>
                  <Select
                    id="fellowshipSub"
                    selectItems={
                      FELLOWSHIP_SUBSPECIALITIES[userDetails.fellowship] || []
                    }
                    onChange={handleInputChange}
                    name="fellowshipSub"
                    value={userDetails.fellowshipSub}
                  />
                  {userDetailsErrors.fellowshipSub && (
                    <div className={styles.error}>
                      {userDetailsErrors.fellowshipSub}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Website</div>
                  <Input
                    name="website"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.website}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.website ? styles.errorInput : ""
                    }
                  />
                  {userDetailsErrors.website && (
                    <div className={styles.error}>
                      {userDetailsErrors.website}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Facebook</div>
                  <Input
                    name="facebook"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.facebook}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.facebook ? styles.errorInput : ""
                    }
                  />
                  {userDetailsErrors.facebook && (
                    <div className={styles.error}>
                      {userDetailsErrors.facebook}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Twitter</div>
                  <Input
                    name="twitter"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.twitter}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.twitter ? styles.errorInput : ""
                    }
                  />
                  {userDetailsErrors.twitter && (
                    <div className={styles.error}>
                      {userDetailsErrors.twitter}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>YouTube</div>
                  <Input
                    name="youtube"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.youtube}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.youtube ? styles.errorInput : ""
                    }
                  />
                  {userDetailsErrors.youtube && (
                    <div className={styles.error}>
                      {userDetailsErrors.youtube}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Instagram</div>
                  <Input
                    name="instagram"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.instagram}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.instagram ? styles.errorInput : ""
                    }
                  />
                  {userDetailsErrors.instagram && (
                    <div className={styles.error}>
                      {userDetailsErrors.instagram}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Additional Links</div>
                  <Row>
                    <Col xl={4} lg={4} md={12} sm={12}>
                      <Input
                        name="additionalLink1Title"
                        type="text"
                        placeholder="Title"
                        value={userDetails.additionalLink1.title}
                        onChange={handleAdditionalLinksChange}
                        className={
                          userDetailsErrors.additionalLink1Title
                            ? styles.errorInput
                            : ""
                        }
                      />
                      {userDetailsErrors.additionalLink1Title && (
                        <div className={styles.error}>
                          {userDetailsErrors.additionalLink1Title}
                        </div>
                      )}
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12}>
                      <Input
                        name="additionalLink1"
                        type="text"
                        placeholder={HTTP_PLACEHOLDER}
                        value={userDetails.additionalLink1.link}
                        onChange={handleAdditionalLinksChange}
                        className={
                          userDetailsErrors.additionalLink1
                            ? styles.errorInput
                            : ""
                        }
                      />
                      {userDetailsErrors.additionalLink1 && (
                        <div className={styles.error}>
                          {userDetailsErrors.additionalLink1}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <div className={`${styles.authInput} mt-3`}>
                    <Row>
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink2Title"
                          type="text"
                          placeholder="Title"
                          value={userDetails.additionalLink2.title}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink2Title
                              ? styles.errorInput
                              : ""
                          }
                        />
                        {userDetailsErrors.additionalLink2Title && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink2Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink2"
                          type="text"
                          placeholder={HTTP_PLACEHOLDER}
                          value={userDetails.additionalLink2.link}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink2
                              ? styles.errorInput
                              : ""
                          }
                        />
                        {userDetailsErrors.additionalLink2 && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink2}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className={styles.signUpTerms}
              >
                By signing up you are agreeing to our{" "}
                <a
                  href={ROUTES.TERMS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service.
                </a>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Button
                  type="large w-100"
                  label={loading ? "loading..." : "Finish My Account"}
                  disabled={loading || isSubmitDisabled}
                  onClick={handleSubmit}
                ></Button>
                {apiError && <Alert variant="danger">{apiError}</Alert>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(UserDetailsForm);
