import React, { Fragment, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

// Constants
import { ROUTES } from "constants/Constants";

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

import GlobalContext from "contexts/Global.context";

// SCSS
import styles from "./Banner.module.scss";

const Banner = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <Fragment>
      <div className={styles.mainBanner}>
        <Container className={styles.bannerInner}>
          <div
            className={styles.bannerInnerWrapper}
            style={{
              backgroundImage: `url(${variantDetails?.assets_url}/home_banner_2.png)`,
            }}
          >
            <Row className="">
              <Col lg="auto" className={styles.contentContainer}>
                <h1>Explore and learn from the best.</h1>
                <div className={styles.subHeading}>
                  {COPY_OVERRIDES?.homepage?.hero_subheader ||
                    "Gain certification and learn on one site."}
                </div>
                <p>
                  <Link to={ROUTES.COURSES}>View All Available Courses</Link>
                </p>
                {/* <h1>Learn new skills from top music experts</h1>
              <div className={styles.subHeading}>
                Video courses complete with action plans for all levels.
              </div> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default React.memo(Banner);
