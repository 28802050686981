import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { useParams, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import queryString from "query-string";

import History from "utils/History";
import GlobalContext from "contexts/Global.context";

// FontAwesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

// Page Components
import CourseLessons from "./Components/CourseLessons/CourseLessons";
import CourseContent from "./Components/CourseContent/CourseContent";
import CourseIntro from "./Components/CourseIntro/CourseIntro";
import PaymentPopup from "components/PaymentPopup/PaymentPopup";
import OtherCourses from "components/OtherCourses/OtherCourses";
import PaymentProcessedPopup from "components/PaymentProcessedPopup/PaymentProcessedPopup";

// Types
import { Modules, Registration } from "interfaces/Course.types";

// services
import {
  fetchCourseDetails,
  getNextLesson,
  completeCourse,
  enterCourse,
} from "services/CourseService";
import { registerUserService } from "services/UserService";

// Constants
import { ROUTES } from "constants/Constants";
import {
  GOALS,
  QUALIFICATIONS
} from "constants/Educations";


// SCSS
import styles from "./CourseDetail.module.scss";

const CourseDetail = () => {
  const { id } = useParams();

  const { search } = useLocation();

  const { isLoggedIn, userDetails, setAuth, variantDetails } = useContext(GlobalContext);

  const [modules, setModules] = useState<Modules[]>([]);
  const [registration, setRegistration] = useState<Registration>(null);
  const [tempModules, setTempModules] = useState<Modules[]>([]);
  const [tempRegistration, setTempRegistration] = useState<Registration>(null);
  const [quiz, setQuiz] = useState<string>(null);
  const [paymentPopup, togglePaymentPopup] = useState<boolean>(false);
  const [paymentProcessedPopup, togglePaymentProcessedPopup] =
    useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [activeLesson, setActiveLesson] = useState(0);
  const [userCreated, setUserCreated] = useState<boolean>(false);
  // const [error, setError] = useState<string>('');
  const affiliateId = search ? queryString.parse(search).affiliateId?.toString() : null;

  const getRegistrationDetails = useCallback(async () => {
    const { error, modules, registration } = await enterCourse(id);
    if (!error) {
      setModules(modules);
      setRegistration(registration);
      if (!search || !queryString.parse(search).retake) {
        setActiveLesson(registration.completed_modules);
      }
    }
  }, [id, search]);

  const handlePaymentAndGetDetails = async (cardId: string, couponId: string) => {
    setProcessing(true);
    const { error, modules, registration } = await enterCourse(id, cardId, couponId);
    if (!error) {
      togglePaymentProcessedPopup(true);
      setTempModules(modules);
      setTempRegistration(registration);
    }
    setProcessing(false);
    togglePaymentPopup(false);
  };

  const getCourseDetails = useCallback(async () => {
    const {
      error,
      modules,
      isRegistered: registered,
      quiz,
    } = await fetchCourseDetails(id);
    if (!error) {
      setIsRegistered(registered);
      setModules(modules);
      if (quiz) {
        setQuiz(quiz);
      }
    }
  }, [id]);

  useEffect(() => {
    getCourseDetails();
  }, [getCourseDetails]);

  useEffect(() => {
    if (variantDetails?.site_short_title === "CCE") {
      if (userDetails && userDetails.id && !userDetails.is_subscribed) {
        History.push(ROUTES.SUBSCRIPTION);
      }
    }
  }, [userDetails, variantDetails?.site_short_title]);

  const gotoPreviousModule = () => {
    setActiveLesson(activeLesson - 1);
  };

  const gotoNextModule = async () => {
    if (activeLesson >= registration.completed_modules) {
      const { error } = await getNextLesson(id);
      if (!error) {
        setRegistration({
          ...registration,
          completed_modules: registration.completed_modules + 1,
        });
      }
    }
    setActiveLesson(activeLesson + 1);
  };

  const createQuickUser = useCallback(async () => {
    setProcessing(true);
    const response = await registerUserService({
      email: `${affiliateId
        .substring(0, 15)
        .replace(/\W/g, "")}@${new Date().getTime()}.com`,
      user_name: `${affiliateId
        .substring(0, 10)
        .replace(/\W/g, "")}_${new Date().getTime()}`,
      password: affiliateId.substring(0, 25),
      affiliate_id: affiliateId,
      course_id: id,
      first_name: "User",
      last_name: "User",
      bio: "Bio",
      /* about: '', */
      categories: "Other",
      city: "LA",
      state: "CA",
      zip: "90001",
      website: "",
      facebook: "",
      twitter: "",
      youtube: "",
      instagram: "",
      industry: "",
      goals: GOALS[0].label,
      other_goals: "",
      professional_classification: QUALIFICATIONS[0].label,
      professional_classification_other: "",
      speciality: "",
      fellowship: "",
      fellowship_sub: "",
    });

    if (!response.ok) {
      // const error = await response.clone().text();
      // setError(error);
      setProcessing(false);
      History.push(`/login?destination=${window.location.pathname}`);
    } else {
      const { token } = await response.json();
      setProcessing(false);
      setAuth(token);
      setUserCreated(true);
    }
  }, [affiliateId, setAuth, id]);

  const purchaseCourse = useCallback(() => {
    if (!isLoggedIn && !affiliateId) {
      History.push(`/login?destination=${window.location.pathname}`);
    } else if (modules.length && modules[0].is_offsite_affiliate && modules[0].external_link) {
      window.open(modules[0].external_link, '_blank');
    } else if (!isLoggedIn && affiliateId) {
      createQuickUser();
    } else if (!!modules[0].cost && !isRegistered) {
      togglePaymentPopup(true);
    } else {
      getRegistrationDetails();
    }
  }, [isLoggedIn, affiliateId, createQuickUser, togglePaymentPopup, getRegistrationDetails, isRegistered, modules]);

  useEffect(() => {
    if (affiliateId && isLoggedIn && userCreated) {
      purchaseCourse();
    }
  }, [isLoggedIn, affiliateId, userCreated, purchaseCourse]);

  const finishCourse = async () => {
    if (!registration.is_completed) {
      const { error } = await completeCourse(id);
      if (!error) {
        setRegistration({
          ...registration,
          is_completed: true,
          completed_modules: modules.length,
        });
        setActiveLesson(activeLesson + 1);
        window.scrollTo(0, 0);
      }
    } else {
      setRegistration({
        ...registration,
        is_completed: true,
        completed_modules: modules.length,
      });
      setActiveLesson(activeLesson + 1);
      window.scrollTo(0, 0);
    }
  };

  const onPaymentDone = () => {
    togglePaymentProcessedPopup(false);
    setModules(tempModules);
    setRegistration(tempRegistration);
    setActiveLesson(tempRegistration.completed_modules);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{variantDetails?.site_title} | Course Details</title>
      </Helmet>
      {modules.length > 0 ? (
        <Container className={styles.courseDetailWrapper}>
          {/* <Row>
            <Col>
              <div
                className={styles.courseDetailTop}
                style={
                  modules[0].banner
                    ? {
                        background: `url(${CLOUDINARY_BIG_BANNER_URL}${modules[0].banner}) no-repeat center right`,
                      }
                    : { minHeight: 0 }
                }
              >
                <div className={styles.courseDetailInner}>
                  <div className={styles.pageHeading}>Learning</div>
                  <div className={styles.pageUser}>{modules[0].title}</div>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className={styles.lessonWrap}>
            <Col lg={3} md={4}>
              <div className={styles.lessonMenu}>
                <CourseLessons
                  activeLesson={activeLesson}
                  lessons={modules}
                  completedLessons={registration?.completed_modules}
                  setActiveLesson={(index) => setActiveLesson(index)}
                  disabled={!registration}
                />
              </div>
            </Col>
            <Col lg={9} md={8}>
              {registration ? (
                <CourseContent
                  quiz={quiz}
                  activeLesson={activeLesson}
                  gotoPreviousModule={gotoPreviousModule}
                  lesson={modules[activeLesson]}
                  completedLessons={registration?.completed_modules}
                  gotoNextModule={gotoNextModule}
                  isCompleted={registration?.is_completed}
                  totalLessons={modules.length}
                  completeCourse={finishCourse}
                  variantDetails={variantDetails}
                />
              ) : (
                <CourseIntro
                  quiz={quiz}
                  description={modules[0].course_description}
                  cost={modules[0].cost}
                  title={modules[0].title}
                  category={modules[0].category}
                  enterCourse={purchaseCourse}
                  activeLesson={activeLesson}
                  lesson={modules[activeLesson]}
                  id={id}
                  isRegistered={isRegistered}
                  isLoading={processing}
                  isOffsite={modules[0].is_offsite_affiliate}
                  externalLink={modules[0].external_link}
                  variantDetails={variantDetails}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={4}></Col>
            <Col lg={9} md={8}>
              <OtherCourses
                heading="Other Courses You May Enjoy"
                query={`relatedOnly=true&course=${id}`}
              />
            </Col>
          </Row>
          {paymentPopup && (
            <PaymentPopup
              cost={modules[0].cost}
              title={modules[0].title}
              onCancel={() => togglePaymentPopup(false)}
              onSubmit={handlePaymentAndGetDetails}
              submitLabel="Proceed"
              loading={processing}
            />
          )}
          {paymentProcessedPopup && (
            <PaymentProcessedPopup
              onCancel={() => togglePaymentProcessedPopup(false)}
              onSubmit={onPaymentDone}
            />
          )}
        </Container>
      ) : null}
    </Fragment>
  );
};

export default React.memo(CourseDetail);
