import React, { Fragment, useContext, useState, useEffect } from "react";
import { Container, Col, Row, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';

import moment from 'moment';
import numeral from 'numeral';
import { Multiselect } from 'multiselect-react-dropdown';
import GlobalContext from "contexts/Global.context";
// Components
import Button from 'components/Form/Button/Button';
import Input from 'components/Form/Input/Input';
import FileUpload from "components/Form/FileUpload/FileUpload";

import MyCards from './MyCards/MyCards';
import NewCard from './NewCard/NewCard';

import { FILE_TYPE, APP_CONSTANTS } from "constants/Constants";
import CONFIGURATION from 'config/Configuration';

import { updateMyProfile, fetchMyTransactions, unsubscribe } from 'services/UserService';

import {
  getUploadURLService,
} from "services/CommonService";

import { Transaction } from 'interfaces/Transaction.types';

// Styles
import styles from "./BillingProfile.module.scss";

const { stripeKey, perMonthSubscriptionCost, perYearSubscriptionCost } = APP_CONSTANTS;

const STRIPE_LOAD = loadStripe(stripeKey);

const renderTooltipCost = (props: any) => (
  <Tooltip
    className={styles.tooltipContainer}
    id="button-tooltipCost"
    {...props}
  >
    Your topics of interest allows us to suggest relevant Courses and
    Credentials. Select an unlimited number of topics. Suggest a new topic to
    learn about or develop a course on by clicking the link.
  </Tooltip>
);

interface BasicInfo {
  firstName: string;
  lastName: string;
  email: string;
  college?: string;
  company?: string;
  userName: string;
  industry?: string;
  categories?: any;
};

interface BasicInfoErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  college?: string;
  company?: string;
  userName?: string;
  industry?: string;
  categories?: string;
};

interface Creds {
  password?: string;
  oldPassword?: string;
  confirmPassword?: string;
};

const DEFAULT_ERRORS = {
  firstName: '',
  lastName: '',
  email: '',
  college: '',
  company: '',
  userName: '',
  industry: '',
  categories: '',
};

const DEFAULT_CREDS = {
  password: '',
  oldPassword: '',
  confirmPassword: ''
};

const REQUIRED_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'userName',
  'password',
  'confirmPassword',
  'oldPassword',
  'categories',
];

const BillingProfile: React.FC = () => {
  const { setAuth, userDetails, categories: categoriesList, variantDetails } = useContext(GlobalContext);
  const { first_name: firstName, last_name: lastName, email, college = '', company = '', user_name: userName, id, is_subscribed, plan, industry = '', categories, photo: current_photo } = userDetails;
  const [basicInfo, setBasicInfo] = useState<BasicInfo>({
    firstName,
    lastName,
    email,
    college,
    company,
    userName,
    industry,
    categories: categories ? categories.split(',').map(item => ({ label: item })) : []
  });
  const [errors, setErrors] = useState<BasicInfoErrors>(DEFAULT_ERRORS);
  const [apiError, setApiError] = useState<string>('');
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);

  const [photo, setPhoto] = useState<null | File>(null);
  const [creds, setCreds] = useState<Creds>(DEFAULT_CREDS);
  const [credsErrors, setCredsErrors] = useState<Creds>(DEFAULT_CREDS);
  const [apiCredsError, setApiCredsError] = useState<string>('');
  const [apiCredsSuccess, setApiCredsSuccess] = useState<boolean>(false);

  const [subsciptionError, setSubscriptionError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const [refreshCount, setRefreshCount] = useState<number>(0);

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const validateForm = () => {
    const formErrors = { ...DEFAULT_ERRORS };
    let isError = false;
    Object.keys(basicInfo).map(key => {
      if (REQUIRED_FIELDS.includes(key) && (!basicInfo[key] || !basicInfo[key].length)) {
        formErrors[key] = 'Required';
        isError = true;
      }
      return key;
    });
    setErrors(formErrors);
    return !isError;
  };

  const validateCredsForm = () => {
    const formErrors = { ...DEFAULT_CREDS };
    let isError = false;
    Object.keys(creds).map(key => {
      if (!creds[key]) {
        formErrors[key] = 'Required';
        isError = true;
      }
      return key;
    });
    if (!isError && (creds.confirmPassword !== creds.password)) {
      formErrors['confirmPassword'] = 'Password and confirm password should be same';
      isError = true;
    }
    else if (!isError && creds.password.length < 8) {
      formErrors['password'] = 'Password must be greater than 8 characters long';
      isError = true;
    }
    setCredsErrors(formErrors);
    return !isError;
  };

  const handleChange = (event: any) => {
    setApiSuccess(false);
    setApiError('');
    const { target: { name, value } } = event;
    setBasicInfo({
      ...basicInfo,
      [name]: value
    });
    if (REQUIRED_FIELDS.includes(name) && !value) {
      setErrors({
        ...errors,
        [name]: 'Required'
      });
    }
    else {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const handlePhotoChange = (photo: File | null) => {
    setPhoto(photo);
  };

  const handleCredsChange = (event: any) => {
    setApiCredsSuccess(false);
    setApiCredsError('');
    const { target: { name, value } } = event;
    setCreds({
      ...creds,
      [name]: value
    });
    if (REQUIRED_FIELDS.includes(name) && !value) {
      setCredsErrors({
        ...credsErrors,
        [name]: 'Required'
      });
    }
    else {
      setCredsErrors({
        ...credsErrors,
        [name]: ''
      });
    }
  };

   const uploadPhoto = async () => {
     setLoading(true);
     let uploadImageResponse: any = null;
     let errorOccurred: any = null;
     if (photo) {
       const fileInfo = {
         name: photo.name,
         type: photo.type,
       };
       const { signedRequest, url, error } = await getUploadURLService(
         fileInfo
       );

       if (signedRequest && url) {
         uploadImageResponse = await fetch(signedRequest, {
           method: "PUT",
           body: photo,
         });
         return { uploadImageResponse, url };
       } else if (error) {
         setApiError(`An error occurred while uploading photo: ${error}`);
         setLoading(false);
         errorOccurred = error;
         return { error };
       }
     }
     return { uploadImageResponse, error: errorOccurred };
   };

  const handleSubmit = async () => {
    setApiError('');
    setApiSuccess(false);
    let photoURL = null;
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    if (photo) {
      const { uploadImageResponse, url }: any = await uploadPhoto();
      if (uploadImageResponse) {
        photoURL = url;
      }
    }
    const { error, token } = await updateMyProfile(id, { ...basicInfo, photo: photoURL || current_photo, categories: basicInfo.categories.map((c) => c.label).join(',') });
    if (error) {
      setApiError(error);
    }
    else if (token) {
      setApiSuccess(true);
      setAuth(token);
    }
    else {
      setApiSuccess(true);
    }
    setLoading(false);
  }

  const handleCredsSubmit = async () => {
    setApiCredsError('');
    setApiCredsSuccess(false);
    if (!validateCredsForm()) {
      return;
    }
    setLoading(true);
    const { error } = await updateMyProfile(id, { password: creds.password, oldPassword: creds.oldPassword });
    if (error) {
      setApiCredsError(error);
    }
    else {
      setApiCredsSuccess(true);
      setCreds(DEFAULT_CREDS);
    }
    setLoading(false);
  }

  const cancelSubscription = async () => {
    if (is_subscribed) {
      setLoading(true);
      setSubscriptionError('');
      const { error, token } = await unsubscribe();
      if (error || !token) {
        setSubscriptionError(error || 'Something went wrong');
      }
      else {
        setAuth(token);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    const getPastTransactions = async () => {
      const { error, transactions } = await fetchMyTransactions();
      if (!error) {
        setTransactions(transactions);
      }
    };
    getPastTransactions();
  }, [setTransactions]);

  const handleCategorySelect = (categories: any) => {
    setBasicInfo({
      ...basicInfo,
      categories
    });
  };

  return (
    <Fragment>
      <div className={styles.billProfile}>
        <div className={styles.billProfileWrapper}>
          <Container>
            <div className={styles.pageHeading}>Billing &amp; Profile</div>
            <Row>
              <Col
                className={`${styles.authForm}`}
                xl={6}
                lg={6}
                md={12}
                sm={12}
              >
                <div className={styles.authFormWrap}>
                  <div className={styles.sectionHeading}>Password</div>
                  <div
                    className={`${styles.authInput} ${styles.authInputPassword}`}
                  >
                    <div className={styles.authLabel}>Previous Password</div>
                    <Input
                      name="oldPassword"
                      type="password"
                      value={creds.oldPassword}
                      onChange={handleCredsChange}
                    />
                    {!!credsErrors.oldPassword && (
                      <div className={styles.error}>
                        {credsErrors.oldPassword}
                      </div>
                    )}
                  </div>
                  <div
                    className={`${styles.authInput} ${styles.authInputPassword}`}
                  >
                    <div className={styles.authLabel}>New Password</div>
                    <Input
                      name="password"
                      type="password"
                      value={creds.password}
                      onChange={handleCredsChange}
                    />
                    {!!credsErrors.password && (
                      <div className={styles.error}>{credsErrors.password}</div>
                    )}
                  </div>
                  <div
                    className={`${styles.authInput} ${styles.authInputPassword}`}
                  >
                    <div className={styles.authLabel}>Confirm New Password</div>
                    <Input
                      name="confirmPassword"
                      type="password"
                      value={creds.confirmPassword}
                      onChange={handleCredsChange}
                    />
                    {!!credsErrors.confirmPassword && (
                      <div className={styles.error}>
                        {credsErrors.confirmPassword}
                      </div>
                    )}
                  </div>
                  <div className={styles.authButton}>
                    <Button
                      type="small"
                      label="Save"
                      disabled={loading}
                      onClick={handleCredsSubmit}
                    />
                  </div>
                  {!!apiCredsError && (
                    <div className={styles.apiResult}>
                      <Alert variant="danger">{apiCredsError}</Alert>
                    </div>
                  )}
                  {apiCredsSuccess && (
                    <div className={styles.apiResult}>
                      <Alert variant="success">
                        Password updated successfully
                      </Alert>
                    </div>
                  )}
                </div>
              </Col>
              <Elements stripe={STRIPE_LOAD}>
                <NewCard onSuccess={() => setRefreshCount(refreshCount + 1)} />
              </Elements>
            </Row>
            <MyCards refresh={refreshCount} />
            {!!is_subscribed && variantDetails?.site_short_title === "CCE" && (
              <div className={styles.membershipContent}>
                <div className={styles.sectionHeading}>Membership</div>
                <div className={styles.membershipStatus}>
                  <div className={styles.statusLeft}>
                    <div className={styles.billedInfoTop}>
                      You are currently <span>Active</span>
                    </div>
                    <div className={styles.billedInfoBottom}>
                      You will be billed{" "}
                      {plan === "yearly"
                        ? perYearSubscriptionCost
                        : perMonthSubscriptionCost}
                    </div>
                  </div>
                  <div className={styles.statusRight}>
                    <Button
                      type="dangerBtn"
                      label="Cancel Membership"
                      onClick={cancelSubscription}
                      disabled={loading}
                    />
                  </div>
                </div>
                {!!subsciptionError && (
                  <Alert variant="danger">{subsciptionError}</Alert>
                )}
              </div>
            )}
            <Row>
              <Col className={`${styles.authForm}`} sm={12}>
                <div className={styles.authFormWrap}>
                  <div className={styles.sectionHeading}>Account</div>
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <div className={`${styles.authInput}`}>
                        <div className={styles.authLabel}>First Name</div>
                        <Input
                          name="firstName"
                          type="text"
                          value={basicInfo.firstName}
                          onChange={handleChange}
                        />
                        {!!errors.firstName && (
                          <div className={styles.error}>{errors.firstName}</div>
                        )}
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <div className={`${styles.authInput}`}>
                        <div className={styles.authLabel}>Last Name</div>
                        <Input
                          name="lastName"
                          type="text"
                          value={basicInfo.lastName}
                          onChange={handleChange}
                        />
                        {!!errors.lastName && (
                          <div className={styles.error}>{errors.lastName}</div>
                        )}
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <div className={`${styles.authInput}`}>
                        <div className={styles.authLabel}>E-mail Address</div>
                        <Input
                          name="email"
                          type="email"
                          value={basicInfo.email}
                          onChange={handleChange}
                        />
                        {!!errors.email && (
                          <div className={styles.error}>{errors.email}</div>
                        )}
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <div className={`${styles.authInput}`}>
                        <div className={styles.authLabel}>Username</div>
                        <Input
                          name="userName"
                          type="text"
                          value={basicInfo.userName}
                          onChange={handleChange}
                        />
                        {!!errors.userName && (
                          <div className={styles.error}>{errors.userName}</div>
                        )}
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Topics of Interest
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipCost}
                          >
                            <div className={styles.infoHover}>i</div>
                          </OverlayTrigger>
                          <span className={styles.shortMess}>
                            <a
                              href={
                                CONFIGURATION?.new_category_link ||
                                "https://musiccareermap.com/category-submission"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              (If you don&apos;t see your topic listed, click
                              here to request it.)
                            </a>
                          </span>
                        </div>
                        <Multiselect
                          options={categoriesList || []}
                          isObject={true}
                          displayValue="label"
                          showCheckbox
                          selectedValues={basicInfo.categories}
                          onSelect={handleCategorySelect}
                          onRemove={handleCategorySelect}
                        />
                        {!!errors.categories && (
                          <div className={styles.error}>
                            {errors.categories}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <div className={styles.authInput}>
                        <div className={styles.authLabel}>
                          Upload Your Photo
                        </div>
                        <FileUpload
                          fileType={FILE_TYPE.IMAGE}
                          fileSpecs={["5mb max", "500px wide", "500px tall"]}
                          onFileChange={handlePhotoChange}
                          alreadyUploadedFileURL={current_photo}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={styles.buttonWrapper}>
                        <Button
                          type="small"
                          label="Save"
                          disabled={loading}
                          onClick={handleSubmit}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className={styles.apiResult}>
                    {!!apiError && (
                      <Col>
                        <div>
                          <Alert variant="danger">{apiError}</Alert>
                        </div>
                      </Col>
                    )}
                    {apiSuccess && (
                      <Col>
                        <div>
                          <Alert variant="success">
                            Details updated successfully
                          </Alert>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className={styles.pastTransListOuter}>
                  <div className={styles.sectionHeading}>Past Transactions</div>
                  <div className={styles.pastTransList}>
                    {transactions.map((transaction) => {
                      return (
                        <div key={transaction.id} className={styles.list}>
                          <div className={styles.lessonName}>
                            <div>{transaction.objectName}</div>
                            {transaction.paidFor}
                          </div>
                          <div className={styles.lessonDate}>
                            Paid on{" "}
                            {moment(transaction.created_at).format(
                              "MMM D, YYYY"
                            )}
                          </div>
                          <div className={styles.lessonPrice}>
                            {numeral(transaction.amount).format("$0.00")}
                          </div>
                        </div>
                      );
                    })}
                    {!transactions.length && <div>No transactions found</div>}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(BillingProfile);
