import React, { Fragment, useContext } from "react";
import SVG from "react-inlinesvg";
import { Container, Row, Col } from "react-bootstrap";

// SCSS
import styles from "./Listing.module.scss";

// Components
import Button from "components/Form/Button/Button";

// Constants
import { ROUTES } from "constants/Constants";
import GlobalContext from "contexts/Global.context";

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

const Listing = () => {
  const { CERTIFICATIONS, COURSES } = ROUTES;
  const { variantDetails } = useContext(GlobalContext);
  return (
    <Fragment>
      <div className={styles.serviceListing}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={4} md={6} sm={12}>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <SVG
                    baseURL="/"
                    description="Access Credentials"
                    src={`${variantDetails?.assets_url}/home_access_certifications.svg`}
                    title="Access Credentials"
                  />
                </div>
                <div className={styles.stepCount}>Step 1</div>
                <div className={styles.heading}>Explore Courses</div>
                <div className={styles.description}>
                  {COPY_OVERRIDES?.homepage?.explore_body || 'Browse a growing list of courses, many of which feature music experts that work with some of the world’s biggest artists and companies.'}
                </div>
                <div className={styles.bannerButtons}>
                  <Button link={COURSES} label="Learn More" type="blank branding" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <SVG
                    baseURL="/"
                    description="Take Quiz"
                    src={`${variantDetails?.assets_url}/home_take_quiz.svg`}
                    title="Take Quiz"
                  />
                </div>
                <div className={styles.stepCount}>Step 2</div>
                <div className={styles.heading}>Take Classes</div>
                <div className={styles.description}>
                  {COPY_OVERRIDES?.homepage?.classes_body || 'Video courses are broken down into individual modules, complete with action plans, real-world examples, reflection questions, and more.'}
                </div>
                <div className={styles.bannerButtons}>
                  <Button link={COURSES} label="Learn More" type="blank branding" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <SVG
                    baseURL="/"
                    description="Earn Certification"
                    src={`${variantDetails?.assets_url}/home_earn_certification.svg`}
                    title="Earn Certification"
                  />
                </div>
                <div className={styles.stepCount}>Step 3</div>
                <div className={styles.heading}>Earn Credentials</div>
                <div className={styles.description}>
                  {COPY_OVERRIDES?.homepage?.earn_body || 'Prove your knowledge and experience with our quizzes to earn credentials to unlock career opportunities, exclusive networks, and more.'}
                </div>
                <div className={styles.bannerButtons}>
                  <Button link={CERTIFICATIONS} label="Learn More" type="blank branding" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default React.memo(Listing);
