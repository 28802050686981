import React, { useState, useContext } from 'react';
import { Row, Col } from "react-bootstrap";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import { Token, StripeError } from '@stripe/stripe-js';

import Button from 'components/Form/Button/Button';
import Input from 'components/Form/Input/Input';

import { addNewCard } from 'services/PaymentService';

import GlobalContext from 'contexts/Global.context';

import styles from '../BillingProfile.module.scss';

const STRIPE_CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#111b47',
      minHeight: '55px',
      border: '1px solid #000000',
      '::placeholder': {
        color: '#979797',
      },
    }
  }
};

interface Props {
  onSuccess: () => void;
}

const NewCard: React.FC<Props> = ({ onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { setAuth } = useContext(GlobalContext);

  const [newCardLoader, setNewCardLoader] = useState<boolean>(false);
  const [newCardError, setNewCardError] = useState<string>('');

  const addCard = async () => {
    setNewCardError('');
    if (!stripe && !elements) {
      return;
    }

    if (stripe && elements) {
      const cardElement = elements.getElement(CardNumberElement);
      if (cardElement) {
        setNewCardLoader(true);
        const { token, error }: { token?: Token | undefined; error?: StripeError | undefined; } = await stripe.createToken(cardElement);
        if (token) {
          const { token: userToken, error: cardError } = await addNewCard(token?.id);
          if (cardError) {
            setNewCardError(cardError);
          }
          else {
            cardElement.clear();
            elements.getElement(CardExpiryElement).clear();
            elements.getElement(CardCvcElement).clear();
            if (userToken) {
              setAuth(userToken);
            }
            onSuccess();
          }
        } else if (error) {
          setNewCardError(error?.message);
        }
        setNewCardLoader(false);
      }
    }
  }

  return (
      <Col xl={6} lg={6} md={12} sm={12}>
        <div>
          <div className={styles.sectionHeading}>Payment Information</div>
          <div className={`${styles.authInput} ${styles.authInputPassword}`}>
            <div className={styles.authLabel}>
              Card Number
            </div>
            <CardNumberElement
              options={STRIPE_CARD_ELEMENT_OPTIONS}
              onChange={() => setNewCardError('')}
              className={`form-control ${styles.cardInput}`}
            />
          </div>
          <Row>
            <Col sm={6}>
              <div className={styles.authInput}>
                <div className={styles.authLabel}>
                  Exp.
                </div>
                <CardExpiryElement
                  options={STRIPE_CARD_ELEMENT_OPTIONS}
                  onChange={() => setNewCardError('')}
                  className={`form-control ${styles.cardInput}`}
                />
              </div>
            </Col>
            <Col sm={6}>
              <div className={styles.authInput}>
                <div className={styles.authLabel}>
                  CVV
                </div>
                <CardCvcElement
                  options={STRIPE_CARD_ELEMENT_OPTIONS}
                  onChange={() => setNewCardError('')}
                  className={`form-control ${styles.cardInput}`}
                />
              </div>
            </Col>
          </Row>
          <div className={`${styles.hidden} ${styles.authInput}`}>
            <div className={styles.authLabel}>
              Name On Card
            </div>
            <Input
              name="name"
              type="text"
            />
          </div>
          <div className={styles.authButton}>
            <Button
              type="small"
              onClick={addCard}
              disabled={newCardLoader}
              label="Add Card"
            />
          </div>
          {newCardError && <div className={styles.apiError}>
            {newCardError}
          </div>}
        </div>
      </Col>
  );
};

export default React.memo(NewCard);
