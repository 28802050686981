import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import DashboardContext from "../../contexts/Dashboard.context";
import styles from './MyStats.module.scss';

interface Props {
  handleTabChange: (tab) => void;
  activeTab: number;
}

const MyStats: React.FC<Props> = ({ handleTabChange, activeTab }) => {
	const {
    completedCoursesCount,
    incompleteCoursesCount,
    completedQuizzesCount,
    incompleteQuizzesCount,
  } = useContext(DashboardContext);
	return (
		<Row>
      <Col>
        <div className={styles.userStats}>
          <div onClick={() => handleTabChange(0)} className={`${styles.userStatsList} ${activeTab === 0 ? styles.activeStat : ''}`}>
            <div className={styles.numberStats}>{completedQuizzesCount}</div>
            <div className={styles.lableStats}>
              Completed Credentials
            </div>
          </div>
          <div onClick={() => handleTabChange(1)} className={`${styles.userStatsList} ${activeTab === 1 ? styles.activeStat : ''}`}>
            <div className={styles.numberStats}>{completedCoursesCount}</div>
            <div className={styles.lableStats}>Completed Courses</div>
          </div>
          <div onClick={() => handleTabChange(2)} className={`${styles.userStatsList} ${activeTab === 2 ? styles.activeStat : ''}`}>
            <div className={styles.numberStats}>{incompleteQuizzesCount}</div>
            <div className={styles.lableStats}>Incomplete Credentials</div>
          </div>
          <div onClick={() => handleTabChange(3)} className={`${styles.userStatsList} ${activeTab === 3 ? styles.activeStat : ''}`}>
            <div className={styles.numberStats}>{incompleteCoursesCount}</div>
            <div className={styles.lableStats}>Incomplete Courses</div>
          </div>
        </div>
      </Col>
    </Row>
	);
}

export default React.memo(MyStats);
