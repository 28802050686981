import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';

// SCSS
import styles from './CareerMap.module.scss';

//IMAGES
import MDR from '../../../../assets/imgs/About/mdr@2x.png';
import MIC from '../../../../assets/imgs/About/mic@2x.png';
import MIA from '../../../../assets/imgs/About/mia@2x.png';
import MCM from '../../../../assets/imgs/About/mcm@2x.png';

const CareerMap = () => {
  return (
    <Fragment>
      <div className={styles.careerMapOuter}>
        <Row>
          <Col>
            <div className={styles.heading}>
              The Music Career Map family includes:
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.careerList}>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <a
                    href="https://musiciansdeskreference.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={MDR} alt="MDR" />
                  </a>
                </div>
                <div className={styles.careerDetails}>
                  <div className={styles.careerheading}>
                    <a
                      href="https://musiciansdeskreference.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Musicians Desk Reference
                    </a>
                  </div>
                  <div className={styles.careerSubheading}>
                    Project Management System
                  </div>
                </div>
              </div>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <a
                    href="http://musicindustry.careers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={MIC} alt="MIC" />
                  </a>
                </div>
                <div className={styles.careerDetails}>
                  <div className={styles.careerheading}>
                    <a
                      href="http://musicindustry.careers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Music Industry Careers
                    </a>
                  </div>
                  <div className={styles.careerSubheading}>
                    Career &amp; Opportunity Marketplace
                  </div>
                </div>
              </div>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <a
                    href="http://musicindustry.academy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={MIA} alt="MIA" />
                  </a>
                </div>
                <div className={styles.careerDetails}>
                  <div className={styles.careerheading}>
                    <a
                      href="http://musicindustry.academy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Music Industry Academy
                    </a>
                  </div>
                  <div className={styles.careerSubheading}>
                    Online Courses, Quizzes &amp; Credentials
                  </div>
                </div>
              </div>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <a href="http://musiccareermap.com" rel="noopener noreferrer">
                    <img src={MCM} alt="MCM" />
                  </a>
                </div>
                <div className={styles.careerDetails}>
                  <div className={styles.careerheading}>
                    <a
                      href="http://musiccareermap.com"
                      rel="noopener noreferrer"
                    >
                      Music Career Map
                    </a>
                  </div>
                  <div className={styles.careerSubheading}>
                    News &amp; Media Outlet
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CareerMap;
