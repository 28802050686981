interface ApiConstants {
  API_URL: string;
}

interface ErrorConstants {
  EMAIL_NOT_ENTERED_ERROR: string;
  EMAIL_IS_NOT_VALID: string;
  PASSWORD_NOT_ENTERED_ERROR: string;
  PASSWORD_CHAR_LENGTH_ERROR: string;
  CONFIRM_PASSWORD_ERROR: string;
  PASSWORDS_MISMATCH_ERROR: string;
  FISRT_NAME_NOT_ENTERED: string;
  LAST_NAME_NOT_ENTERED: string;
  USER_NAME_NOT_ENTERED: string;
  USER_NAME_INVALID: string;
  SOMETHING_WENT_WRONG: string;
}

interface RegExValidators {
  EMAIL_VALIDATOR_REGEX: RegExp;
  USER_NAME_VALIDATOR: RegExp;
  URL_VALIDATOR: RegExp;
  ZIP_VALIDATOR: RegExp;
}

interface PageConstants {
  ITEMS_PER_PAGE: number;
}

interface JobConstants {
  JOB_SUBMIT_SUCCESS: string;
  JOB_UPDATE_SUCCESS: string;
}

interface Constants {
  apiConstants: ApiConstants;
  errorConstants: ErrorConstants;
  regExValidators: RegExValidators;
  pageConstants: PageConstants;
  jobConstants: JobConstants;
  stripeKey: string;
  featuredAmount: number;
  postCost: number;
  perMonthSubscriptionCost: string;
  perYearSubscriptionCost: string;
  perMonthSubscriptionAmount: number;
  perYearSubscriptionAmount: number;
}

export const BASE_URL: string = "https://bp-mia-api.herokuapp.com";

export const APP_CONSTANTS: Constants = {
  apiConstants: {
    API_URL: BASE_URL,
  },
  errorConstants: {
    EMAIL_NOT_ENTERED_ERROR: 'Please enter an email address',
    EMAIL_IS_NOT_VALID: 'Please enter a valid email address',
    PASSWORD_NOT_ENTERED_ERROR: 'Please enter your password',
    PASSWORD_CHAR_LENGTH_ERROR:
      'Please enter minimum 8 characters in the password',
    CONFIRM_PASSWORD_ERROR: 'Please enter confirm password',
    PASSWORDS_MISMATCH_ERROR: 'Passwords do not match',
    FISRT_NAME_NOT_ENTERED: 'Please enter your first name',
    LAST_NAME_NOT_ENTERED: 'Please enter your last name',
    USER_NAME_NOT_ENTERED: 'Please enter your user name',
    USER_NAME_INVALID: 'Accepted characters are A-Z, a-z, 0-9, _, -',
    SOMETHING_WENT_WRONG: 'Something went wrong, please try again.',
  },
  regExValidators: {
    //eslint-disable-next-line
    EMAIL_VALIDATOR_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    USER_NAME_VALIDATOR: /^[a-zA-Z0-9-_]+$/,
    URL_VALIDATOR: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i,
    ZIP_VALIDATOR: /^\d{1,5}$/,
  },
  pageConstants: {
    ITEMS_PER_PAGE: 15,
  },
  jobConstants: {
    JOB_SUBMIT_SUCCESS: 'Job posted successfully.',
    JOB_UPDATE_SUCCESS: 'Job updated successfully.',
  },
  stripeKey:
    process.env.REACT_APP_STRIPE_KEY || 'pk_test_51H9bdSEGEDgwNG44X17gZl4nFYzeVXs9Od751FzeJst7u9YI8zfMWbYyLapXxWoGKpzL4SteooM3b6Tjo5E7JW7A00UlELDVKn',
  featuredAmount: 19.99,
  postCost: 10,
  perMonthSubscriptionCost: "$15/month",
  perYearSubscriptionCost: "$100/year",
  perMonthSubscriptionAmount: 15,
  perYearSubscriptionAmount: 100
};

/**
 * Constants for route paths
 */
export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  ABOUT: "/about",
  TERMS: "/terms",
  PRIVACY: "/privacy",
  SUPPORT: "/support",
  ACCOUNT_VERIFY: "/account-verify",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  DASHBOARD: "/dashboard",
  QUIZ_NODE: "/dashboard/quizzes/:id",
  CERT_SUMMARY: "/quizzes/:id",
  CERT_SUMMARY_PARTIAL: "/quizzes",
  MY_CERTIFICATIONS: "/dashboard/certifications",
  PROFILE: "/dashboard/profile",
  QUIZ_DETAIL: "/dashboard/quizzes",
  COURSES: "/courses",
  CERTIFICATIONS: "/certifications",
  SUBSCRIPTION: "/subscription",
  SEARCH: "/search",
};

export const API_TYPE = {
  VALIDATE_TOKEN: 'authorize',
  USERS: 'users',
  VALIDATE_CREDENTIALS: 'users/validate-credentials',
  LOGIN: 'login',
  COURSES: 'courses',
  QUIZZES: 'quizzes',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  GET_UPLOAD_URL: 'get-upload-url',
  CARDS: 'cards',
  FETCH_CATEGORIES: 'categories',
  SUBSCRIPTION: 'subscribe',
  VERIFY_COUPON: 'coupons/verify',
  VARIANTS: 'variants',
};

/**
 * Constants for Backend API Endpoints
 */
export const API_ENDPOINTS = {
  // User related enpoints
  VALIDATE_TOKEN: `${BASE_URL}/${API_TYPE.VALIDATE_TOKEN}`,
  VALIDATE_REGISTRATION_DATA: `${BASE_URL}/${API_TYPE.VALIDATE_CREDENTIALS}`,
  USERS: `${BASE_URL}/${API_TYPE.USERS}`,
  LOGIN: `${BASE_URL}/${API_TYPE.LOGIN}`,
  FORGOT_PASSWORD: `${BASE_URL}/${API_TYPE.FORGOT_PASSWORD}`,
  RESET_PASSWORD: `${BASE_URL}/${API_TYPE.RESET_PASSWORD}`,

  GET_UPLOAD_URL: `${BASE_URL}/${API_TYPE.GET_UPLOAD_URL}`,

  FETCH_TOP_COURSES: `${BASE_URL}/${API_TYPE.COURSES}`,

  FETCH_TOP_QUIZZES: `${BASE_URL}/${API_TYPE.QUIZZES}`,

  CARDS: `${BASE_URL}/${API_TYPE.CARDS}`,

  FETCH_CATEGORIES: `${BASE_URL}/${API_TYPE.FETCH_CATEGORIES}`,

  SUBSCRIPTION: `${BASE_URL}/${API_TYPE.SUBSCRIPTION}`,

  VERIFY_COUPON: `${BASE_URL}/${API_TYPE.VERIFY_COUPON}`,

  VALIDATED_VARIANT: `${BASE_URL}/${API_TYPE.VARIANTS}`,
};

// Secret api key to access the backend endpoints
export const SECRET_API_KEY = 'M!AK0@';

export const FILE_TYPE = {
  IMAGE: 'image',
  DOCUMENT: 'document',
};

export const ITEMS_PER_PAGE = 20;

export const DEFAULT_OFFSET = 0;

export const DEFAULT_PAGINATION_PARAMS = {
  limit: ITEMS_PER_PAGE,
  skip: DEFAULT_OFFSET,
};

export const JOB_TYPE = {
  career: 'career',
  opportunity: 'opportunity',
};

export const INDUSTRIES = [
  { label: "Music" },
  { label: "Film/Video" },
  { label: "Dance" },
  { label: "Theater" },
  { label: "Fashion" },
  { label: "Visual Arts/Design" },
  { label: "Media" },
  { label: "Sports" },
  { label: "Culinary" }
];

export const COST_OPTIONS = [
  {
    label: 'Free',
    value: 'free'
  },
  {
    label: 'Premium (Not Free)',
    value: 'premium'
  }
];

export const CONTENT_TYPE_OPTIONS = [
  {
    label: 'Education',
    value: 'Education'
  },
  {
    label: 'Training',
    value: 'Training'
  },
]

export const MIC_CONTACT_EMAIL = 'info@musiccareermap.com';

export const API_METHODS = {
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  PUT: 'PUT',
};

export const DEFAULT_API_HEADER = {
  'Content-Type': 'application/json',
  // Todo: move secret key in .env
  'x-api-key': SECRET_API_KEY,
};

export const HOME_CATEGORIES = [
  '',
  'Production',
  'Mixing',
  'Electronic',
  'Guitar'
];
export const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com/careermap/image/upload';
export const CERTIFICATIONS_PAGE_HEADING = 'Test your knowledge and earn credentials.';
export const COURSES_PAGE_HEADING = 'Learn new skills from top music experts';
export const CERTIFICATIONS_PAGE_SUBHEADING = 'Credentials are certifications that prove you have been educated and tested on industry-standard topics. Credentials can unlock careers, exclusive networking opportunities, discounts on goods and services, and more. Credentials are available after select courses and require a passing grade via multiple choice quiz.';
export const CLOUDINARY_BANNER_URL = 'https://res.cloudinary.com/careermap/image/fetch/c_fill_pad,g_auto,w_600,h_400/';
export const CLOUDINARY_BIG_BANNER_URL = 'https://res.cloudinary.com/careermap/image/fetch/c_fill_pad,g_auto,w_1200,h_205/';
