import React, { Fragment } from "react";

import History from "utils/History";
import { useParams } from "react-router-dom";

// img
import Complete from "assets/imgs/complete-icon.png";

// SCSS
import styles from "./CourseComplete.module.scss";

// Components
import Button from "components/Form/Button/Button";

interface Props {
  quiz?: string;
}

const CourseComplete: React.FC<Props> = ({ quiz }) => {
  const { id }: any = useParams();

  const gotoQuiz = () => {
    History.push(`/dashboard/quizzes/${quiz}`);
  };

  const retakeCourse = () => {
    window.location.href = `/courses/${id}?retake=true`;
  };

  return (
    <Fragment>
      <div className={styles.courseCompleteWrapper}>
        <div className={styles.imgWrapper}>
          <img src={Complete} alt="Complete Course" />
        </div>
        <div className={styles.completeHeading}>
          <div>Congratulations!</div>
          <div>You’ve completed the course.</div>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            onClick={retakeCourse}
            label="Retake The Course"
            type="large ghostBtn"
          />
          {quiz && (
            <Button
              onClick={gotoQuiz}
              label="Take Quiz Now"
              type="large ghostBtn"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(CourseComplete);
