import React, {
  Fragment,
  useEffect,
  useCallback,
  useState,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { Row, Col, ProgressBar } from "react-bootstrap";

// Components
import Button from "components/Form/Button/Button";
import QuestionAndOptions from "components/QuestionAndOptions/QuestionAndOptions";
import Result from "./Result/Result";
import Retake from "assets/svgs/retake.svg";

// Types
import { QuizQuestion } from "interfaces/Quiz.types";

// Context
import GlobalContext from "contexts/Global.context";
import { ROUTES } from "constants/Constants";

// services
import {
  fetchQuizDetails,
  submitAnswer,
  downloadCert,
} from "services/QuizService";

// Styles
import styles from "./QuizNode.module.scss";

const QuizNode = () => {
  const { id } = useParams();
  const { CERTIFICATIONS } = ROUTES;

  const { setQuizDetails } = useContext(GlobalContext);

  const [question, setQuestion] = useState<QuizQuestion>(null);

  const [score, setScore] = useState<any>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [showRetake, setShowRetake] = useState<boolean>(false);

  const [showDownload, setShowDownload] = useState<boolean>(false);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [answer, setAnswer] = useState<string>(null);

  const getQuiz = useCallback(
    async (retake: boolean = false) => {
      setShowRetake(false);
      const { error, quiz, result, registration, answers, completed, passed } =
        await fetchQuizDetails(id, retake);
      if (!error && quiz) {
        setQuizDetails({
          title: quiz.title,
          category: quiz.category,
        });
        if (completed) {
          setShowRetake(true);
          if (passed) {
            setShowDownload(true);
          }
          return;
        }
        if (result) {
          setScore({
            result,
            registration,
            answers,
          });
        }
        setQuestion(quiz);
      }
    },
    [id, setQuizDetails]
  );

  useEffect(() => {
    getQuiz();
  }, [getQuiz]);

  const submitAnswerAndProceed = async () => {
    setIsSubmitting(true);
    const {
      error,
      question: nextQuestion,
      result,
      registration,
      answers,
    } = await submitAnswer(id, {
      option: answer,
      question: question.question_id,
    });
    if (!error) {
      setIsSubmitting(false);
      if (!result) {
        setAnswer(null);
        setQuestion(nextQuestion);
      } else {
        setScore({
          result,
          registration,
          answers,
        });
      }
    }
  };

  const getProgress = () => {
    return (
      (question.registration.completed_questions * 100) /
      question.total_questions
    );
  };

  const retakeQuiz = () => {
    getQuiz(true);
  };

  const downloadCertificate = async () => {
    setIsDownloading(true);
    const { error, data } = await downloadCert(id);
    if (!error) {
      window.open(data.url, "__blank");
      // downloadjs(data, `certificate_${userDetails.first_name}_${userDetails.last_name}.pdf`, 'application/pdf');
    }
    setIsDownloading(false);
  };

  return (
    <Fragment>
      <Row>
        <Col>
          {question ? (
            <div className={styles.quizNodeWrapper}>
              <div className={`${styles.quizTop} ${styles.contentWidth}`}>
                <div className={styles.duration}>
                  Est. Time: {question.estimated_time} Minutes
                </div>
                <div className={styles.progressBar}>
                  <ProgressBar
                    now={!score ? getProgress() : 100}
                    className={styles.progress}
                  />
                </div>
                {score ? (
                  <div>
                    <Result
                      result={score.result}
                      answers={score.answers}
                      registration={score.registration}
                    />
                  </div>
                ) : null}
                {!score ? (
                  <div className={styles.questionCount}>
                    Question {question.registration?.completed_questions + 1} of{" "}
                    {question.total_questions ||
                      question.registration?.total_questions}
                  </div>
                ) : null}
              </div>
              {question && !score ? (
                <div className={styles.quizContent}>
                  <QuestionAndOptions
                    question={question.question}
                    answer={answer}
                    image={question.image}
                    options={question.options}
                    setAnswer={setAnswer}
                    submitAnswer={submitAnswerAndProceed}
                    disabledSubmit={isSubmitting || !answer}
                  />
                  {/*<div className={styles.btnBack}>
                  <Button label="Go Back" type="large btnArrowLeft" />
                </div>*/}
                </div>
              ) : null}
            </div>
          ) : null}
          {showRetake ? (
            <div className={styles.quizNodeWrapper}>
              <div className={`${styles.retakeWrap} ${styles.contentWidth}`}>
                <div className={styles.subTitle}>One moment...</div>
                <div className={styles.title}>
                  You've already taken this quiz.
                </div>
                <div className={styles.retakeImage}>
                  <img src={Retake} alt="Retake" />
                </div>
                <div className={styles.retakeButtons}>
                  {!!showDownload && (
                    <Button
                      onClick={downloadCertificate}
                      disabled={isDownloading}
                      label="Download Your Certificate"
                      type="branding"
                    />
                  )}
                  <Button
                    onClick={retakeQuiz}
                    label="Let me take it again"
                    type="branding"
                  />
                </div>
                <Button
                  link={CERTIFICATIONS}
                  label="Go Back"
                  type="large btnArrowLeft"
                />
              </div>
            </div>
          ) : null}
        </Col>
      </Row>
    </Fragment>
  );
};

export default React.memo(QuizNode);
