import React, { useState, useEffect, useCallback } from "react";

import { Row, Col } from "react-bootstrap";

// Components
import Course from "components/Course/Course";

// Types
import { CourseList } from "interfaces/CourseList.types";

// Services
import { fetchRelatedCourses } from "services/CourseService";

//SCSS;
import styles from "./OtherCourses.module.scss";

const OTHER_COURSES = 3;
const ORDER_BY = 'registrations';

interface Props {
  heading: string;
  query: string;
  course?: string;
};

const OtherCourses: React.FC<Props> = ({
  query,
  heading,
  course,
}) => {

  const [courses, setCourses] = useState<CourseList[]>([]);

  const getRelatedCourses = useCallback(async () => {
    const { courses, error } = await fetchRelatedCourses(query, OTHER_COURSES, ORDER_BY);
    if (!error) {
      setCourses(courses);
    }
  }, [setCourses, query]);

  useEffect(() => {
    getRelatedCourses();
  }, [getRelatedCourses]);

  return (
    <div className={styles.otherCourses}>
      <Row>
        <Col>
          <div className={styles.sectionHeading}>
            {heading}
          </div>
        </Col>
      </Row>
      <Row>
      {courses.length ? courses.map((course, index) => {
        return (
          <Col key={index} xl="4" lg="4" md="6" xs="12">
            <Course
              id={course.id}
              image={course.banner}
              category={course.category}
              title={course.title}
              description={course.description}
              count={course.registrations}
              isOffsite={course.is_offsite_affiliate}
              externalLink={course.external_link}
            />
          </Col>
        );
      }) : <div className="loader"><span></span><span></span></div>}
      </Row>
    </div>
  );
};

export default React.memo(OtherCourses);