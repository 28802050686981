import React, { useContext } from "react";
import { Helmet } from 'react-helmet';

// Home Context
import HomeState from './contexts/Home.state';
import GlobalContext from 'contexts/Global.context';

// Components
import Banner from "./components/Banner/Banner";
import OurPartner from "./components/OurPartner/OurPartner";
import DeepDive from "./components/DeepDive/DeepDive";
import Listing from "./components/Listing/Listing";
import TopCourses from "./components/TopCourses/TopCourses";
import TopCertifications from "./components/TopCertifications/TopCertifications";
import Slider from "./components/Slider/Slider";
// import Dashboard from "../../pages/Dashboard/Dashboard";
// import CourseDetail from "../../pages/CourseDetail/CourseDetail";

const Home = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <>
      <Helmet>
        <title>{variantDetails?.site_title} | Home</title>
      </Helmet>
      <HomeState>
        <Banner />
        <DeepDive />
        <Listing />
        <TopCourses />
        <TopCertifications />
        <OurPartner />
        <Slider />
      </HomeState>
    </>
  );
};

export default React.memo(Home);
