import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "components/Form/Button/Button";

import styles from './PaymentProcessedPopup.module.scss';

// SVGs
import Checkout from "assets/svgs/checkout-cart.svg";

interface Props {
  onSubmit: () => void;
  onCancel: () => void;
}

const PaymentProcessedPopup: React.FC<Props> = ({ onSubmit, onCancel }) => {
  const [show, setShow] = useState<boolean>(true);

  const handleClose = (submitted?: boolean) => {
    setShow(false);
    onCancel();
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Body>
        <div className={styles.proccessPayment}>
          <div className={styles.imgWrapper}>
            <img src={Checkout} alt="checkout" />
          </div>
          <div className={styles.paymentHeading}>Your payment has been processed.</div>
          <div className={styles.paymentSubHeading}>You have successfully purchased the course.</div>
          <div className={styles.btnWrapper}>
            <Button onClick={onSubmit} type="primary" label="Start Course Now" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(PaymentProcessedPopup);