import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "components/Image/Image";

// SCSS
import styles from "./CertificationsHeader.module.scss";

interface Props {
  header: string;
  image?: string;
  pageContent?: string;
}

const CertificationsHeader: React.FC<Props> = ({
  header,
  image,
  pageContent,
}) => {
  return (
    <Fragment>
      <div className={styles.mainCertificationsHeader}>
        <Container>
          <Row className="justify-content-lg-between justify-content-md-center align-items-center justify-content-sm-center text-center text-lg-left">
            <Col sm="auto">
              <h1>{header}</h1>
              <p
                className={styles.pageSubHeading}
                dangerouslySetInnerHTML={{ __html: pageContent }}
              />
            </Col>
            <Col sm="auto">
              <Image src={image} title="Image" />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default React.memo(CertificationsHeader);
