import { API_ENDPOINTS, API_METHODS, DEFAULT_API_HEADER } from '../constants/Constants';

export const fetchMyCards = async () => {
  try {
    const response = await fetch(`${API_ENDPOINTS.CARDS}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const cards = await response.json();
      return { cards: cards?.sources?.data || [] };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const addNewCard = async (token: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.CARDS}`, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ token }),
    });

    if (response.ok) {
      const { token } = await response.json();
      return { token };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const deleteMyCard = async (cardId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.CARDS}/${cardId}`, {
      method: API_METHODS.DELETE,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const { deleted } = await response.json();
      return { deleted };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};