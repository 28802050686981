import React, { Fragment } from "react";
import SVG from "react-inlinesvg";
import { Container, Row, Col } from "react-bootstrap";

// SVGs
import Explore from "../../../../assets/svgs/explore.svg";
import Classes from "../../../../assets/svgs/classes.svg";
import Earn from "../../../../assets/svgs/earn.svg";

// SCSS
import styles from "./Listing.module.scss";

// Components
import Button from "../../../../components/Form/Button/Button";

// Constants
import { ROUTES } from "constants/Constants";

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

const Listing = () => {
  const { CERTIFICATIONS, COURSES } = ROUTES;
  return (
    <Fragment>
      <div className={styles.serviceListing}>
        <Container>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <SVG
                    baseURL="/"
                    description="Access Credentials"
                    src={Explore}
                    title="Access Credentials"
                  />
                </div>
                <div className={styles.stepCount}>Step 1</div>
                <div className={styles.heading}>Access Credentials</div>
                <div className={styles.description}>
                  Take the associated course or jump ahead to the quiz if you feel prepared.
                </div>
                <div className={styles.bannerButtons}>
                  <Button link={COURSES} label="Learn More" type="blank branding" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <SVG
                    baseURL="/"
                    description="Take Quiz"
                    src={Classes}
                    title="Take Quiz"
                  />
                </div>
                <div className={styles.stepCount}>Step 2</div>
                <div className={styles.heading}>Take Quiz</div>
                <div className={styles.description}>
                  Test your knowledge through a series of questions, grades available upon completion.
                </div>
                <div className={styles.bannerButtons}>
                  <Button link={COURSES} label="Learn More" type="blank branding" />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className={styles.list}>
                <div className={styles.imgWrapper}>
                  <SVG
                    baseURL="/"
                    description="Earn Certification"
                    src={Earn}
                    title="Earn Certification"
                  />
                </div>
                <div className={styles.stepCount}>Step 3</div>
                <div className={styles.heading}>Earn Credentials</div>
                <div className={styles.description}
                  dangerouslySetInnerHTML={{ __html: COPY_OVERRIDES?.certifications?.earn_body || 'Add to your resume, <a target="__blank" href="https://musicindustry.careers">Music Industry Careers page</a>, LinkedIn and more' }}
                >
                </div>
                <div className={styles.bannerButtons}>
                  <Button link={CERTIFICATIONS} label="Learn More" type="blank branding" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default React.memo(Listing);
