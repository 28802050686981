import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useContext
} from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SVG from "react-inlinesvg";

import QuizLock from "assets/svgs/quiz-lock.svg";
import History from "utils/History";

import Button from "components/Form/Button/Button";

import GlobalContext from "contexts/Global.context";

// Types
import { QuizSummary } from "interfaces/Quiz.types";

// services
import { fetchQuizSummary } from "services/QuizService";

// Constants
import { ROUTES } from "constants/Constants";

// SCSS
import styles from "./CertificationSummary.module.scss";

const CertificationSummary = () => {
  const { id } = useParams();
  const { variantDetails } = useContext(GlobalContext);
  const [quiz, setQuiz] = useState<QuizSummary>(null);
  const [quizCourse, setQuizCourse] = useState<{ id: string; title: string }>(null);

  console.log(112)

  const getCertificationSummary = useCallback(async () => {
    const {
      error,
      quiz,
      relatedCourse
    } = await fetchQuizSummary(id);
    if (!error) {
      if (quiz) {
        setQuiz(quiz);
      }
      if (relatedCourse) {
        setQuizCourse(relatedCourse);
      }
    }
  }, [id]);

  useEffect(() => {
    getCertificationSummary();
  }, [getCertificationSummary]);

  const startQuiz = () => {
    History.push(`${ROUTES.QUIZ_DETAIL}/${id}`);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{variantDetails?.site_title} | Credential Summary</title>
      </Helmet>
      {quiz ? (
        <Container className={styles.quizSummaryWrapper}>
          <Row>
            <Col>
              <div className={styles.quizTop}>
                <div className={styles.quizHeading}>Quiz</div>
                <div className={styles.quizTitle}>{quiz.title}</div>
                <div className={styles.quizCategory}>{quiz.category}</div>
                <div
                  className={styles.quizImage}
                  style={{
                    backgroundImage: `url(${variantDetails?.assets_url}/dashboard_heading.png)`,
                  }}
                ></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container className={styles.quizContent}>
                <div className={styles.quizLock}>
                  <SVG
                    baseURL="/"
                    description="Lock"
                    src={QuizLock}
                    title="Lock"
                  />
                </div>
                <div className={styles.duration}>
                  Est. Time: {quiz.estimated_time} Minutes
                </div>
                <div className={styles.title}>{quiz.title}</div>
                <div className={styles.desc}>{quiz.description}</div>
                {quizCourse &&
                  <div className={styles.relatedCourse}>
                    Click here to access the associated Training Course: <Link to={`${ROUTES.COURSES}/${quizCourse?.id}`}>{quizCourse.title}</Link>
                  </div>}
                <div className={styles.actionNote}>
                  To take this quiz you will need to sign in.
                </div>
                <div className={styles.startBtn}>
                  <Button
                    onClick={startQuiz}
                    label="Start Quiz"
                    type="branding"
                  />
                </div>
              </Container>
            </Col>
          </Row>

        </Container>
      ) : null}
    </Fragment>
  );
};

export default React.memo(CertificationSummary);
