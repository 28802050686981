import React, { useContext, useEffect } from 'react';
import { Router, Switch, Route, useLocation } from 'react-router-dom';

// History
import History from "./utils/History";

// Routes path
import { ROUTES } from './constants/Constants';
import { PrivateRoute } from './routes/Private.routes';

import GlobalContext from './contexts/Global.context';

// Components
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import About from './pages/About/About';
import Support from './pages/Support/Support';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Dashboard from "./pages/Dashboard/Dashboard";
import CourseDetail from "./pages/CourseDetail/CourseDetail";
import Certifications from "./pages/Certifications/Certifications";
import Courses from "./pages/Courses/Courses";
import CertificationSummary from "./pages/CertificationSummary/CertificationSummary";
import Search from "./pages/Search/Search";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import Subscription from "./pages/Subscription/Subscription";

const AppRoutes = () => {
  const { isLoggedIn } = useContext(GlobalContext);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <Router history={History}>
        <Switch>
          <Route exact path={ROUTES.HOME} component={Home} />
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.ABOUT} component={About} />
          <Route exact path={ROUTES.TERMS} component={Terms} />
          <Route exact path={ROUTES.PRIVACY} component={Privacy} />
          <Route exact path={ROUTES.SUPPORT} component={Support} />
          <Route exact path={ROUTES.REGISTER} component={Register} />
          <Route
            exact
            path={ROUTES.CERTIFICATIONS}
            component={Certifications}
          />
          <Route exact path={ROUTES.COURSES} component={Courses} />
          <Route exact path={ROUTES.CERT_SUMMARY} component={CertificationSummary} />
          <Route exact path={ROUTES.SEARCH} component={Search} />
          <Route
            exact
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <PrivateRoute
            path={ROUTES.DASHBOARD}
            component={Dashboard}
            isLoggedIn={isLoggedIn}
          />
          <Route
            exact
            path={`${ROUTES.COURSES}/:id`}
            component={CourseDetail}
          />
          <PrivateRoute
            isLoggedIn={isLoggedIn}
            path={ROUTES.SUBSCRIPTION}
            component={Subscription}
          />
        </Switch>
      </Router>
      <Footer />
    </>
  );
};

export default AppRoutes;
