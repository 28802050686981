import React, { Fragment } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

// SVGs
import Oval from "../../assets/svgs/oval.svg";

// Styles
import styles from "./LetUsHelp.module.scss";

const LetUsHelp = () => {
  return (
    <Fragment>
      <div className={styles.helpOuter}>
        <div className={styles.logo}>
          <SVG
            baseURL="/"
            cacheRequests={true}
            description="Let Us Help"
            src={Oval}
            title="Let Us Help"
          />
        </div>
        <div className={styles.content}>
          Having trouble knowing which topic to pick?
          <div>
            <Link to="/#">Let us help.</Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(LetUsHelp);
