import React, { Fragment, useContext } from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";

//Components

import LetUsHelp from "../../../../components/LetUsHelp/LetUsHelp";

// Context
import GlobalContext from "contexts/Global.context";

import { ROUTES } from "constants/Constants";

import CONFIGURATION from 'config/Configuration';

// Styles
import styles from "./DashboardMenu.module.scss";

import Project from "../../../../assets/svgs/project.svg";
import Career from "../../../../assets/svgs/career.svg";
import Expert from "../../../../assets/svgs/expert.svg";

const DashboardMenu = () => {
  const { logout, variantDetails } = useContext(GlobalContext);
  return (
    <Fragment>
      <div className={styles.dashMenu}>
        <ul>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={`${styles.menuItem} ${styles.dashboard}`}
              to={ROUTES.DASHBOARD}
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink className={`${styles.menuItem} ${styles.quizes}`} to={ROUTES.COURSES}>
              All Courses
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`${styles.menuItem} ${styles.certificate}`}
              to={ROUTES.CERTIFICATIONS}
            >
              All Credentials
            </NavLink>
          </li>
          <li>
            <NavLink className={`${styles.menuItem} ${styles.topics}`} to={ROUTES.HOME}>
              All Topics
            </NavLink>
          </li>
          {/*<li>
            <NavLink className={`${styles.menuItem} ${styles.library}`} to="#/">
              Document Library
            </NavLink>
          </li>*/}
          
          <li>
            <NavLink className={`${styles.menuItem} ${styles.billing}`} to={ROUTES.PROFILE}>
              Billing &amp; Profile
            </NavLink>
          </li>
          <li>
            <NavLink className={`${styles.menuItem} ${styles.support}`} to={ROUTES.SUPPORT}>
              Support
            </NavLink>
          </li>
          <li>
            <NavLink onClick={logout} className={`${styles.menuItem} ${styles.signOut}`} to="">
              Sign Out
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.lineBreak}></div>
      <div className={styles.externalLinks}>
        {CONFIGURATION?.dashboard_external_links ? <ul>
          {CONFIGURATION?.dashboard_external_links?.map((item, index) => 
            <li key={index}>
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.externalMenuItem}`}
              >
              <SVG
                baseURL="/"
                description={item.title}
                src={`${variantDetails?.assets_url}/${item.icon}`}
                title={item.title}
              />
                {item.title}
              </a>
            </li>
          )}
        </ul>
        :
        <ul>
          <li>
            <a
              href="https://musicindustry.careers/"
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.externalMenuItem}`}
            >
            <SVG
              baseURL="/"
              description="Music Industry Careers"
              src={Career}
              title="Music Industry Careers"
            />
              Careers
            </a>
          </li>
          <li>
            <a
              href="https://musiccareermap.com/expert-consulting/"
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.externalMenuItem}`}
            >
            <SVG
              baseURL="/"
              description="Music Career Maps"
              src={Expert}
              title="Music Career Maps"
            />
              Experts
            </a>
          </li>
          <li>
            <a
              href="https://musiciansdeskreference.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.externalMenuItem}`}
            >
            <SVG
              baseURL="/"
              description="Music Career Maps"
              src={Project}
              title="Music Career Maps"
            />
              Projects
            </a>
          </li>
        </ul>}
      </div> 
      <LetUsHelp />
    </Fragment>
  );
};

export default DashboardMenu;
