import React, { Fragment, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import GlobalContext from "contexts/Global.context";

//Components
import Sidebar from '../Sidebar/Sidebar';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './Welcome.module.scss';

const Welcome = () => {
  const { variantDetails } = useContext(GlobalContext);
  return (
    <Fragment>
      <Row>
        <Col md={variantDetails?.site_short_title === 'MIA' ? 6 : 12}>
          <div className={styles.mainHeading}>
            {COPY_OVERRIDES?.about?.header || 'We’re redefining music education.'}
            {/* Welcome to <span>your music</span> professional network./ */}
          </div>
          <p dangerouslySetInnerHTML={{__html: COPY_OVERRIDES?.about?.body || `Let’s face it: music education can be a boring topic, 
            but it certainly doesn’t have to be. 
            We’ve gathered the top music experts worldwide to offer their knowledge,
            experience and perspective, all wrapped in easy to navigate courses, 
            all with action plans to start utilizing post-course. 
            You’ll have the ability to test your skills to earn certifications, 
            which can be used to unlock career opportunities and deals on music goods and services. 
            Even better, you’ll be able to connect with our expert instructors after the courses to continue to learn, network, and grow your career.
            &nbsp;<u>
              This is what music education was meant to be.
            </u>`}}>
            
          </p>
        </Col>
        {variantDetails?.site_short_title === 'MIA' && <Col md={6}>
          <Sidebar />
        </Col>}
      </Row>
      <Row>
        <Col>
          <div className={styles.content} dangerouslySetInnerHTML={{__html: COPY_OVERRIDES?.about?.paragraphs || `<p>
              Music Industry Careers is a part of the Music Career Map family, a
              series of platforms built to help musicians, music professionals,
              educators and students gain access to the best education and
              career development resources.
            </p>

            <p>
              Music Career Map was developed by Brian Penick professional
              musician, entrepreneur, investors and writer with more than two
              decades of experience working in music, ranging from developing
              artists that have reached platinum status to launching music
              companies like The Counter Rhythm Group, Soundstr (acquired) and
              many more.
            </p>`}}>
            
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Welcome;
