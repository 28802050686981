import React, { Fragment, useContext, useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

// Context
import HomeContext from '../../contexts/Home.context';
import GlobalContext from 'contexts/Global.context';

import History from 'utils/History';

// Components
import Certificate from "components/Certificate/Certificate";
import Select from 'components/Form/Select/Select';
import Button from "../../../../components/Form/Button/Button";

// Constants
import { INDUSTRIES } from "constants/Constants";

//SCSS;
import styles from "./TopCertifications.module.scss";

const TOP_QUIZZES = 4;
const ORDER_BY = 'registrations';

const TopCertifications = () => {
  const { categories, variantDetails } = useContext(GlobalContext);
  const { quizzes, getQuizzes, totalQuizzes, isLoading } = useContext(HomeContext);
  const [ industry, setIndustry ] = useState<string>('');
  const [ category, setCategory ] = useState<string>('');

  useEffect(() => {
    getQuizzes({ category, industry }, TOP_QUIZZES, ORDER_BY);
  }, [category, industry, getQuizzes]);

  const viewAllQuizzes = () => {
    History.push(`/certifications`);
  }

  return (
    <Fragment>
      <div className={styles.topCoursesWapper}>
        <Container>
          <Row>
            <Col>
              <div className={styles.sectionHeading}>Top Credentials</div>
            </Col>
          </Row>
          <div className={styles.tabsParent}>
            <div className={styles.filterSection}>
              <Row className="align-items-center">
                <Col sm="auto">
                  <div className={styles.filterLabel}>Filter By</div>
                </Col>
                <Col sm="auto">
                  <Select
                    id="state"
                    name="state"
                    onChange={(e) => setCategory(e.target.value)}
                    selectItems={categories}
                    value={category}
                    placeholder="Select Category"
                  />
                </Col>
                {!variantDetails?.site_short_title.includes(
                  "BeyondPhysician"
                ) && (
                  <Col sm="auto">
                    <Select
                      id="industry"
                      name="industry"
                      onChange={(e) => setIndustry(e.target.value)}
                      selectItems={INDUSTRIES}
                      value={industry}
                      placeholder="Select Industry"
                    />
                  </Col>
                )}
              </Row>
            </div>
            <div className={styles.tabContent}>
              <div className="tab-item-wrapper">
                <Row>
                  {!!quizzes.length &&
                    quizzes.map((quiz, index) => {
                      return (
                        <Col key={index} xl="3" lg="3" md="6" xs="12">
                          <Certificate
                            id={quiz.id}
                            image={quiz.banner}
                            category={quiz.category}
                            title={quiz.title}
                            description={quiz.description}
                            registrations={quiz.registrations}
                          />
                        </Col>
                      );
                    })}
                  {isLoading && (
                    <div className="loader">
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </Row>
              </div>
            </div>
            {!!totalQuizzes && (
              <span className={styles.resultsCount}>
                {totalQuizzes} Results
              </span>
            )}
          </div>
          {quizzes.length ? (
            <Row>
              <Col className="text-center">
                <div className={styles.btnOuter}>
                  <Button
                    onClick={viewAllQuizzes}
                    label="View All"
                    type="large ghostBtn ghostBtnGray"
                  />
                </div>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </Fragment>
  );
};

export default React.memo(TopCertifications);
