import React, { Fragment, useState } from "react";
// import downloadjs from 'downloadjs';
import SVG from "react-inlinesvg";
import History from 'utils/History';

import { ROUTES, CLOUDINARY_BANNER_URL } from 'constants/Constants';
import { getLimited } from 'utils/UtilityFunctions';

// SVGs
import Trophy from "assets/svgs/trophy.svg";

// FontAwesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUsers } from "@fortawesome/free-solid-svg-icons";

import { downloadCert } from 'services/QuizService';

// import GlobalContext from 'contexts/Global.context';

// SCSS
import styles from "./Certificate.module.scss";

// Props
interface ICertificate {
  category: string;
  title: string;
  id?: string;
  image?: string;
  registrations?: string | number;
  description?: string;
  downloadable?: boolean;
}

const Certificate = (props: ICertificate) => {
  
  // const { userDetails } = useContext(GlobalContext);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  
  const getCategoryColor = () => {
    if (props.category === 'Production') {
      return styles.green;
    }
    if (props.category === 'Guitar') {
      return styles.purple;
    }
    if (props.category === 'Electronic') {
      return styles.blue;
    }
    return styles.normal;
  }

  const gotoDetails = () => {
    if (props.downloadable) {
      return;
    }
    History.push(`${ROUTES.CERT_SUMMARY_PARTIAL}/${props.id}`);
  }

  const downloadCertificate = async () => {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);
    const { error, data } = await downloadCert(props.id);
    if (!error) {
      window.open(data.url, "__blank");
      // downloadjs(data, `certificate_${userDetails.first_name}_${userDetails.last_name}.pdf`, 'application/pdf');
    }
    setIsDownloading(false);
  };

  return (
    <Fragment>
      <div className={`${styles.listWrapper} ${props.downloadable ? styles.noLink : ''}`} onClick={gotoDetails}>
        <div className={styles.imgWrapper}>
          {props.image ?
            <img alt={props.title} src={`${CLOUDINARY_BANNER_URL}${props.image}`} />
            :
            <SVG
            baseURL="/"
            description="Certificate"
            src={Trophy}
            title="BP"
            />
          }
        </div>
        <div className={styles.courseDetails}>
          <div className={styles.courseCat}><span className={getCategoryColor()}>{props.category}</span></div>
          <div className={styles.courseTitle}>{props.title}</div>
          <div className={styles.courseDescription}>{getLimited(props.description, 150)}</div>
          {/* {!props.downloadable && props.registrations >= 0 && <div className={styles.memberCount}>
            <FontAwesomeIcon className={styles.users} icon={faUsers} />
            <div className={styles.text}>
              <span> {props.registrations}</span> completed this quiz
            </div>
          </div>} */}
          {/*eslint-disable-next-line*/}
          {props.downloadable && <a href="javascript:void(0)" className={styles.downloadLink} onClick={downloadCertificate}>View Certificate</a>}
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(Certificate);
