import React, { Fragment, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ROUTES } from "constants/Constants";
import Image from "components/Image/Image";

// Components
import Button from "components/Form/Button/Button";

// Text
import COPY_OVERRIDES from "config/CopyOverrides";
import GlobalContext from "contexts/Global.context";

//SCSS;
import styles from "./DeepDive.module.scss";

const DeepDive = () => {
  const { variantDetails } = useContext(GlobalContext);
  const { COURSES } = ROUTES;
  const DeepDiveSvg = `${variantDetails?.assets_url}/${
    COPY_OVERRIDES?.homepage?.deep_dive_image || "home_deep_dive.svg"
  }`;
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col>
                  <div className={styles.deepDiveWapper}>
                    <div className={styles.sectionHeading}>
                      Deep dive into a large range of topics.
                    </div>
                    <p>
                      {COPY_OVERRIDES?.homepage?.deep_dive_body ||
                        "Courses are available for musicians, music professionals, students, educators, and more, ranging from introductory to advanced and expert-level."}{" "}
                    </p>
                    <div className={styles.bannerButtons}>
                      <Button
                        link={COURSES}
                        label="Learn More"
                        type="blank branding"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Image
              className={styles.sectionBanner}
              src={DeepDiveSvg}
              title="Deep Dive Image"
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default React.memo(DeepDive);
