import React, { useState } from "react";
import numeral from 'numeral';
import Modal from "react-bootstrap/Modal";
import Button from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";
import Cards from "./Cards";

import {
  verifyCouponService
} from 'services/CommonService';

import styles from './PaymentPopup.module.scss';

interface CouponResponse {
  id: string;
  amount: number;
}

interface Props {
  cost: number;
  onSubmit: (cardId: any, couponId?: string) => void;
  onCancel: () => void;
  submitLabel: string;
  loading: boolean;
  title: string;
}

const PaymentPopup: React.FC<Props> = ({ cost, onSubmit, onCancel, submitLabel, loading, title }) => {
  const [show, setShow] = useState<boolean>(true);
  const [selectedCard, setSelectedCard] = useState<string>(null);
  const [coupon, setCoupon] = useState<CouponResponse | null>(null);
  const [amount, setAmount] = useState<number>(cost);
  const [couponError, setCouponError] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const handleClose = (submitted?: boolean) => {
    setShow(false);
    onCancel();
  }

  const handleCodeChange = (event: any) => {
    setCouponError('');
    setCode(event.target.value);
  };

  const verifyCouponCode = async () => {
    if (coupon) {
      setCode('');
      setCoupon(null);
      setAmount(cost);
      return;
    }
    if (!code) {
      return;
    }
    setCouponError('');
    setIsVerifying(true);
    const { id, type, error } = await verifyCouponService(code);

    if (id) {
      setCoupon({
        id,
        amount: type === 'full' ? cost : cost / 2,
      });

      setAmount(type === 'full' ? 0 : cost / 2);

      setIsVerifying(false);
    } else if (error) {
      setCouponError(error);
      setIsVerifying(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-lg"
    >
      <Modal.Header closeButton={!loading}>
        <Modal.Title><div className={styles.modalHeading}>Check Out</div></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.paymentDetails}>
          <div className={styles.courseDetails}>
            <div className={styles.courseName}>{title}</div>
            <div className={styles.coursePrice}>{numeral(cost).format('$0.00')}</div>
          </div>
          <div className={styles.priceDetails}>
            <div className={styles.courseName}>Total Cost</div>
            <div className={styles.coursePrice}>{numeral(cost).format('$0.00')}</div>
          </div>
        </div>
        <div className={styles.couponCode}>
          <Input
            type="text"
            placeholder="Coupon Code"
            onChange={handleCodeChange}
            value={code}
            name="code"
          />
          <Button
            onClick={verifyCouponCode}
            buttonType="button"
            label={!coupon ? 'Apply' : 'Remove'}
            type={`branding ${styles.applyCouponBtn}`}
            disabled={isVerifying || !code}
            isLoading={isVerifying}
          />
          {couponError && (
            <div className={styles.error}>
              {couponError}
            </div>
          )}
        </div>
        
        {amount > 0 ? (
          <>
            <div className={styles.sectionHeading}>
              {coupon
                ? `Applied coupon worth $${coupon.amount.toFixed(2)}. `
                : ''}{' '}
              Please provide credit card information to pay ${amount.toFixed(2)}.
            </div>
            <Cards onCardSelected={(card) => setSelectedCard(card)} />
          </>
        ) : (
          <div className={`${styles.sectionHeading} ${styles.appliedCoupon}`}>
            {coupon ? `Applied coupon worth $${coupon.amount}. ` : ''}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="primary w-100" disabled={(amount && !selectedCard) || loading} onClick={() => onSubmit(selectedCard, coupon?.id)} label={submitLabel}></Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(PaymentPopup);