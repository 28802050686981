import React from 'react';
import { CourseList } from 'interfaces/CourseList.types';
import { QuizList } from 'interfaces/QuizList.types';

interface homeInterface {
  courses: CourseList[];
  quizzes: QuizList[];
  isLoading: boolean;
  totalCourses: number;
  totalQuizzes: number;
  setCourses: (courses: CourseList[]) => void;
  setQuizzes: (quizzes: QuizList[]) => void;
  setIsLoading: (loading: boolean) => void;
  getCourses: (filters?: any, limit?: number, orderBy?: string) => void;
  getQuizzes: (filters?: any, limit?: number, orderBy?: string) => void;
}

const home: homeInterface = {
  courses: [],
  quizzes: [],
  isLoading: false,
  totalCourses: 0,
  totalQuizzes: 0,
  setIsLoading: () => {},
  setCourses: () => {},
  getCourses: () => {},
  setQuizzes: () => {},
  getQuizzes: () => {}
};

export default React.createContext(home);
