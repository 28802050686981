import React from "react";
import { createRoot } from "react-dom/client";
import { HostValidationWrapper } from "./HostValidationWrapper";

const container = document.getElementById("root");
const root = createRoot(container!);


root.render(
  <HostValidationWrapper />
);
