import React, { useState, Fragment, useContext } from "react";
import { Alert } from 'react-bootstrap';

//Components
import Cards from "../../components/PaymentPopup/Cards";
import Button from 'components/Form/Button/Button';
import Input from "components/Form/Input/Input";

import History from 'utils/History';
import GlobalContext from 'contexts/Global.context';
import { APP_CONSTANTS, ROUTES } from "constants/Constants";
import { subscribe } from 'services/UserService';
import { verifyCouponService } from 'services/CommonService';

// Styles
import styles from "./Subscription.module.scss";

interface CouponResponse {
    id: string;
    amount: number;
  }

const Subscription = () => {
    const { setAuth } = useContext(GlobalContext);
    const [selectedCard, setSelectedCard] = useState<string>(null);
    const [coupon, setCoupon] = useState<CouponResponse | null>(null);
    const [couponError, setCouponError] = useState<string>('');
    const [plan, setPlan] = useState<string>("yearly");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [isVerifying, setIsVerifying] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');
    const [amount, setAmount] = useState<number>(plan === "yearly" ? APP_CONSTANTS.perYearSubscriptionAmount : APP_CONSTANTS.perMonthSubscriptionAmount);
    
    const onSubmit = async () => {
        setError("");
        if (!selectedCard) {
            return;
        }
        setIsLoading(true);
        const { error, token } = await subscribe(selectedCard, plan, coupon?.id);
        setIsLoading(false);
        if (error || !token) {
            setError(error || "Something went wrong");
        }
        if (token) {
            setAuth(token);
            History.push(ROUTES.PROFILE);
        }
    }

    const handleCodeChange = (event: any) => {
        setCouponError('');
        setCode(event.target.value);
    };

    const handlePlanChange = (val) => {
        const cost = val === "yearly" ? APP_CONSTANTS.perYearSubscriptionAmount : APP_CONSTANTS.perMonthSubscriptionAmount;
        setPlan(val);
        setCoupon(null);
        setCode('');
        setAmount(cost);
    }
    
    const verifyCouponCode = async () => {
        const cost = plan === "yearly" ? APP_CONSTANTS.perYearSubscriptionAmount : APP_CONSTANTS.perMonthSubscriptionAmount;
        if (coupon) {
            setCode('');
            setCoupon(null);
            setAmount(cost);
            return;
        }
        if (!code) {
          return;
        }
        setCouponError('');
        setIsVerifying(true);
        const { id, type, error } = await verifyCouponService(code);

        if (id) {
          setCoupon({
            id,
            amount: type === 'full' ? cost : cost / 2,
          });
    
          setAmount(type === 'full' ? 0 : cost / 2);
    
          setIsVerifying(false);
        } else if (error) {
          setCouponError(error);
          setIsVerifying(false);
        }
      };

    return (
        <Fragment>
            <div className={styles.subsOuter}>
                <div className={styles.subsContent}>
                    <div className={styles.subsheading}>You are almost done!</div>
                    <div className={styles.subsSubheading}>Select your plan, add your card, submit payment, and you are set.</div>
                    <div className={styles.subsDesc}>If you don't have a paid account you can create one now and join for $5.99/month or $100/year. You can cancel anytime.</div>
                    <div className={styles.confirmSec}>
                        <div className={styles.conHeading}>Confirm Purchase</div>
                        <div className={styles.paymentDetails}>
                            <div onClick={() => handlePlanChange("monthly")} className={`${plan === "monthly" ? styles.activePlan : ''} ${styles.priceDetails}`}>
                                <div className={styles.courseName}>Monthly Premium Membership</div>
                                <div className={styles.coursePrice}>{APP_CONSTANTS.perMonthSubscriptionCost}</div>
                            </div>
                            <div onClick={() => handlePlanChange("yearly")} className={`${plan === "yearly" ? styles.activePlan : ''} ${styles.priceDetails}`}>
                                <div className={styles.courseName}>Yearly Premium Membership (Nearly 45% Savings)</div>
                                <div className={styles.coursePrice}>{APP_CONSTANTS.perYearSubscriptionCost}</div>
                            </div>
                        </div>

                        <div className={styles.couponCode}>
                            <Input
                                type="text"
                                placeholder="Coupon Code"
                                onChange={handleCodeChange}
                                value={code}
                                name="code"
                            />
                            <Button
                                onClick={verifyCouponCode}
                                buttonType="button"
                                label={!coupon ? 'Apply' : 'Remove'}
                                type={`branding ${styles.applyCouponBtn}`}
                                disabled={isVerifying || !code}
                                isLoading={isVerifying}
                            />
                            {couponError && (
                                <div className={styles.error}>
                                {couponError}
                                </div>
                            )}
                        </div>
                            
                        {amount > 0 ? (
                            <>
                                <div className={styles.sectionHeading}>
                                {coupon
                                    ? `Applied coupon worth $${coupon.amount.toFixed(2)}. `
                                    : ''}{' '}
                                Please provide credit card information to pay ${amount.toFixed(2)}.
                                </div>
                                <Cards onCardSelected={(card) => setSelectedCard(card)} />
                            </>
                            ) : (
                            <div className={`${styles.sectionHeading} ${styles.appliedCoupon}`}>
                                {coupon ? `Applied coupon worth $${coupon.amount}. ` : ''}
                            </div>
                        )}
                        <div className={styles.buttonOuter}>
                            <Button type="primary w-100" disabled={!selectedCard || isLoading} onClick={onSubmit} label="Submit Payment"></Button>
                        </div>
                        {error && <Alert className={styles.error} variant="danger">{error}</Alert>}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default React.memo(Subscription);
